<template>
  <a class="component-link-big" :href="specificData.href">
    {{ specificData.text }}
  </a>
</template>

<script>
import MenuItemResolverMixin from "@/components/menu/provision/MenuItemResolverMixin";
import localizeResolver from "@/common/mixins/localizeResolver";
import {UrlPath} from "@/common/helpers";

export default {
  name: "componentLinkBig",
  mixins: [ MenuItemResolverMixin, localizeResolver ],
  computed: {
    specificData() {
      let link = this.itemData.data?.fields?.url;
      if (UrlPath.isRelative(link)) {
        link = UrlPath.join(link, this.localizeConfig?.homeUrl);
      }
      return {
        text: this.itemData.text,
        href: link,
        originHref: this.itemData.data?.fields?.url,
        homeUrl: this.localizeConfig?.homeUrl,
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.component-link-big {
  display: inline-block;
  width: fit-content;
  position: relative;
  text-transform: uppercase;
  color: white;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.6;
  text-decoration: none;
  white-space: nowrap;

  // margin-bottom: 38px;

  @media screen and (max-width: 1280px) {
    text-transform: capitalize;
  }

  &:active,
  &:visited {
    text-decoration: none;
    color: white;
  }

  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #fff;
    visibility: hidden;
    transition: all .3s ease-in-out;
  }

  &:hover {
    &:before {
      visibility: visible;
      width: 100%;
    }
  }

  ::v-deep {
    & + .component-link-big, 
    & + .component-link-small
    {
      // margin-top: 1rem;
      margin-top: 12px;
    }


    @media screen and (max-width: 1280px) {
      line-height: 50px;

      & + .component-link-big,
      & + .component-link-small
      {
        margin-top: 0;
      }
      & + .component-mobile-app-link {
        margin-top: 2rem;
      }
    }
  }
}
</style>
