import Vue from "vue";
import { findIndex } from "lodash";
import config from "@/config";
import { getCookie } from "../../cookie.js";

const API = config.apiAccount;

export default {
  state: {
    event: {},
    events: [],
    pastEvents: [],
    pastEventsCount: 0,
  },
  actions: {
    async getEventsList(ctx) {
      const authorization = getCookie("user");
      const res = await fetch(`${API}/events`, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: authorization,
        }),
      });
      if (!res.ok) return Promise.reject(`Server error. Status: ${res.status}`);
      const eventsList = await res.json();
      ctx.commit("updateEventsList", eventsList?.events || []);
      ctx.commit("setPastEventsCount", eventsList?.past_events);
    },
    async getPastEventsList(ctx) {
      const authorization = getCookie("user");
      const res = await fetch(`${API}/events/past`, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: authorization,
        }),
      });
      if (!res.ok) return Promise.reject(`Server error. Status: ${res.status}`);
      const eventsList = await res.json();
      ctx.commit("updatePastEventsList", eventsList.events);
    },
    async getEvent(ctx, eventId) {
      const authorization = getCookie("user");
      let res = await fetch(`${API}/events/${eventId}`, {
        method: "GET",
        headers: new Headers({
          // "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: authorization,
        }),
      });
      if (!res.ok) return Promise.reject(`Server error. Status: ${res.status}`);
      let event = await res.json();

      // TODO: Get rid of this
      event.ticketCollection = {};
      if (event.tickets) {
        event.tickets.forEach((ticket) => {
          event.ticketCollection[ticket.id] = ticket;
        });
      }

      ctx.commit("updateEvent", event);
    },
    updateEventTickets(ctx, data) {
      let event = ctx.state.event;
      if (!data) return;

      let ticketIndex = findIndex(event.tickets, { id: data.id });
      if (ticketIndex === -1) return;

      // TODO: Create mutation for this
      Vue.set(event.tickets, ticketIndex, {
        ...event.tickets[ticketIndex],
        ...data,
      });
      ctx.commit("updateEvent", event);
    },
    // updateEventTickets(ctx, data) {
    //   let event = ctx.state.event;
    //   if (+data["reg_choice_owner_id"] === +event.id) {
    //     Vue.set(event.ticketCollection, data["id"], {
    //       ...event.ticketCollection[data["id"]],
    //       ...data,
    //     });
    //   }
    //   ctx.commit("updateEvent", event);
    // },
  },
  mutations: {
    updateEvent(state, event) {
      state.event = event;
    },
    updateEventsList(state, list) {
      state.events = list;
    },
    setPastEventsCount(state, count) {
      state.pastEventsCount = count;
    },
    updatePastEventsList(state, list) {
      state.pastEvents = list;
    },
    setTicketProcessing(state, { barcode, status }) {
      let { event } = state;
      let ticketIndex = findIndex(event.tickets, { barcode });

      if (ticketIndex === -1) return;
      event.tickets[ticketIndex].processing = status;
    },
  },
  getters: {
    eventData(state) {
      return state.event;
    },
    eventsList(state) {
      return state.events;
    },
    pastEventsList(state) {
      return state.pastEvents;
    },
    hasPastEvents(state) {
      return state.pastEventsCount > 0;
    },
  },
};
