export default {
  list: [],
  current: {},
  myTeams: [],
  pagination: {
    page: 1,
    per_page: 5
  },
  teamEvents: [],
  teamEventMembers: [],
  teamMembers: [],
  teamMembersBottom: [],
  leaderboardListParams: {
    offset: 1,
    limit: 15,
    category: "",
    year: "",
    sort: "events",
    sortTypes: ["events", "distance_km", "top_finish", "trifectas"],
    direction: "desc",
    search: "",
    total: 0
  },
  leaderboardCategories: [],
  leaderboardYears: []
};
