<template>
  <div class="tickets-cart-button">
    <div v-if="useAccountCheckout" class="button-layout" @click="goToCheckout">
      <div class="button-content">
        <div>{{ $t("commit_now") }}</div>
        <div class="tickets-count">
          <img src="./../../assets/tickets-cart.svg" alt="cart" />
          <span class="counter">{{ ticketsCount }}</span>
        </div>
      </div>
    </div>
    <a
      v-if="!useAccountCheckout"
      :href="tsCheckoutLink"
      target="_blank"
      class="button-layout"
      @click="clearCart"
    >
      <div class="button-content">
        <div>{{ $t("commit_now") }}</div>
        <div class="tickets-count">
          <img src="./../../assets/tickets-cart.svg" alt="cart" />
          <span class="counter">{{ ticketsCount }}</span>
        </div>
      </div>
    </a>
  </div>
</template>
<script>
import commitLinkResolver from "./commitLinkResolver";
export default {
  mixins: [commitLinkResolver],
};
</script>
<style lang="scss" scoped>
.tickets-cart-button {
  z-index: $zindex-page-sticky-el;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 77px;
  width: 100%;
  background: #cf1019;
  box-shadow: 0px -6px 24px rgba(0, 0, 0, 0.1);

  transform: translateY(100%);
  transition: transform 150ms ease-in-out;
  @media screen and (max-width: 1025px) {
    transform: translateY(0);
  }
  .button-layout {
    padding-top: 19px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    height: 100%;

    text-transform: uppercase;
    font-size: 14px;
    line-height: 17px;
    font-weight: bold;
    text-decoration: none;
    @include cursorPointer;

    .button-content {
      display: flex;
      flex-direction: row;
      align-items: center;

      .tickets-count {
        position: relative;
        display: flex;
        margin-left: 12px;
        .counter {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: #cc092f;
        }
      }
    }
  }
}
</style>