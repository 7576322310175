<template>
  <div class="component-menu-column">
    <slot v-if="!isMobile" />
    <component-menu-tree-node v-else :item="item" :references="references" />
  </div>
</template>

<script>
import MenuItemResolverMixin from "@/components/menu/provision/MenuItemResolverMixin";
import ComponentMenuTreeNode from "@/components/menu/content-parts/componentMenuTreeNode";

export default {
  name: "componentMenuColumn",
  components: { ComponentMenuTreeNode },
  mixins: [MenuItemResolverMixin],
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.component-menu-column {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  max-width: 100%;
  padding: 0 15px;

  &:first-child {
    padding-left: 0;
  }

  @media screen and (max-width: 1280px) {
    padding: 0;
  }

  &::v-deep {
    & + .component-link-small {
      display: inline-block;
      flex: 1 0;
      height: fit-content;
    }
    & + .component-menu-sidebar {
      flex-basis: 575px;
      padding: 35px 0 40px 110px;
      border-left: 1px solid #2a2929;
      box-sizing: border-box;

      @media screen and (max-width: 1280px) {
        margin-top: 30px;
        margin-bottom: 40px;
        flex-basis: 0;
        border: none;
        padding: 0 20px;
      }
    }
  }
}
</style>
