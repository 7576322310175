import { get } from "lodash";

export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    references: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    itemData() {
      const linkType = this.item?.sys?.linkType;
      const id = this.item?.sys?.id;
      const data = get(this.references, `${linkType}.${id}`);
      return {
        id,
        linkType,
        origin: { ...this.item },
        data,
        text: get(data, "fields.text"),
        title: get(data, "fields.title"),
        mobileOnly: get(data, "fields.mobileOnly"),
        type: get(data, "sys.contentType.sys.id"),
        children: get(data, "fields.components"),
      };
    },
  },
};
