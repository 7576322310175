<template>
  <div class="country-switcher">
    <div class="close-header">
      <div class="close-btn cursor-pointer" @click="closeOverlay()" v-if="userData && userData.spartan_country_code">
        <i class="material-icons">close</i>
      </div>
    </div>
    <div class="countries-list">
      <div class="list-title">{{ $t("select_your_country") }}</div>
      <div class="list-item" v-for="(region, i) in regionsList" v-bind:key="i">
        <region-item
          :region="region"
          :id="i"
          v-on:language-change="closeOverlay"
        />
      </div>
      <div class="fake-border"></div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { regions } from "../../lists";
import RegionItem from "./RegionItem.vue";

export default {
  data: () => ({
    regionsList: regions,
  }),
  computed: mapGetters(["userData"]),
  components: {
    RegionItem,
  },
  methods: {
    closeOverlay() {
      this.$emit("handle-regions-overlay");
    },
  },
  created() {
    document.body.style.overflow = "hidden"
  },
  destroyed() {
    document.body.removeAttribute("style")
  }
};
</script>
<style lang="scss" scoped>
.country-switcher {
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow-y: scroll;
  .close-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    .close-btn {
      background: #000000;
      height: 44px;
      width: 44px;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      i {
        font-size: 32px;
      }
    }
  }
  .countries-list {
    color: #000000;
    max-width: 855px;
    // border: 2px solid #000000;
    width: 100%;
    // border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    .list-title {
      font-weight: 800;
      font-size: 35px;
      line-height: 45px;
      letter-spacing: 0.0025em;

      margin-bottom: 60px;
    }
  }
}
@media screen and (max-width: 1279px) {
  .country-switcher {
    padding: 10px;
    .close-header {
      min-height: 32px;
      .close-btn {
        height: 32px;
        width: 32px;
        i {
          font-size: 24px;
        }
      }
    }
    .countries-list {
      max-width: 100%;
      .list-title {
        font-size: 20px;

        margin-bottom: 30px;
      }
      .list-item {
        border-top: 1px solid rgba(0, 0, 0, 0.4);
      }
      .fake-border {
        height: 1px;
        width: 100%;
        background: rgba(0, 0, 0, 0.4);
      }
      // .list-item:last-child {
      //   border-bottom: 1px solid rgba(0, 0, 0, 0.4);
      // }
    }
  }
}
</style>