var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{class:{
    'auth-wrap': _vm.authWrap,
    'full-height-overlay': !_vm.showHeader,
    // move ZD widget up
    'has-tickets': _vm.showTicketsCart || _vm.$route.name === 'checkout',
    ..._vm.localeClass,
  }},[(_vm.showHeader)?_c('Toolbar',{attrs:{"showSidebar":_vm.sidebarIsOpen},on:{"handle-sidebar":_vm.handleSidebar,"handle-regions-overlay":_vm.handleRegionsOverlay}}):_vm._e(),(_vm.showTicketsCart)?_c('tickets-cart-button'):_vm._e(),_c('div',{staticClass:"mem-sidebar-overlay",attrs:{"id":"overlay"}}),_c('transition',{attrs:{"name":"fade-transition","mode":"out-in"}},[(_vm.sidebarIsOpen)?_c('div',{staticClass:"sidebar-overlay",on:{"click":function($event){return _vm.handleSidebar()}}}):_vm._e()]),_c('transition',{attrs:{"name":"slide"}},[(_vm.sidebarIsOpen)?_c('Sidebar',{on:{"handle-sidebar":_vm.handleSidebar,"handle-regions-overlay":_vm.handleRegionsOverlay}}):_vm._e()],1),_c('v-overlay',{attrs:{"value":_vm.showCountryOverlay,"opacity":"1","color":"#FFFFFF","z-index":"1000002"}},[_c('region-switcher',{on:{"handle-regions-overlay":_vm.handleRegionsOverlay}})],1),_c('v-main',[_c('v-container',{attrs:{"fluid":""}},[_c('router-view')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }