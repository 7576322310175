import {
  TICKETS_COOKIE_NAME,
  getCookie,
  setCookie,
  setCookieV2,
} from "@/cookie";
import config from "@/config";
import { map, findIndex, forEach, keyBy, merge } from "lodash";
const API = config.apiGate;

export default {
  state: {
    cart: [],
    ticketsList: [],
    cartCountry: null,
    cartDetails: {},
  },
  actions: {
    async getCart(ctx) {
      let cartToken = getCookie(TICKETS_COOKIE_NAME);
      if (!cartToken) return;
      let authorization = getCookie("user");

      const headers = new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
      });
      if (authorization) headers.append("Authorization", authorization);

      let res = await fetch(`${API}/cart?token=${cartToken}`, {
        method: "GET",
        headers,
      });

      if (!res.ok)
        return Promise.reject({
          status: res.status,
          text: "User cart error: something went wrong",
        });
      let result = await res.json();

      ctx.commit("setCart", result.cart);
      ctx.commit("setCartDetails", result.details);
    },
    async updateCart(ctx, details) {
      
      if (!details) localStorage.removeItem("orderId");

      let cartToken = getCookie(TICKETS_COOKIE_NAME);
      let authorization = getCookie("user");
      let { ticketsList } = ctx.state;
      const headers = new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
      });
      if (authorization) headers.append("Authorization", authorization);
      let res = await fetch(
        `${API}/cart${cartToken ? `?token=${cartToken}` : ""}`,
        {
          method: "PATCH",
          headers,
          body: JSON.stringify({ items: ticketsList, details }),
        }
      );
      if (!res.ok)
        return Promise.reject({
          status: res.status,
          text: "User cart error: something went wrong",
        });

      let result = await res.json();
      setCookie(TICKETS_COOKIE_NAME, result.token, 30);
      ctx.commit("setCart", result.cart);
      ctx.commit("setCartDetails", result.details);
    },
    async deleteCart(ctx) {
      let cartToken = getCookie(TICKETS_COOKIE_NAME);
      // const authorization = getCookie("user");
      if (!cartToken) return;

      let res = await fetch(`${API}/cart?token=${cartToken}`, {
        method: "DELETE",
      });
      if (!res.ok)
        return Promise.reject({
          status: res.status,
          text: "User cart error: something went wrong",
        });
      let result = await res.json();
      console.log(result);
      setCookieV2(TICKETS_COOKIE_NAME, "", { "max-age": -1 });
      ctx.commit("setCart", []);
      ctx.commit("setCartDetails", {});
    },

    async updateTicketsList(ctx, tickets) {
      let { ticketsList, cartCountry } = ctx.state;
      //
      let ticketsCountry = tickets[0].country;

      // default flow
      if (!cartCountry || cartCountry === ticketsCountry) {
        forEach(tickets, (ticket) => {
          let ticketId = `${ticket.country}${ticket.ts_ticket_type_id}`;
          let ticketIndex = findIndex(ticketsList, { ts_id: ticketId });

          if (ticketIndex >= 0) {
            ticketsList.splice(ticketIndex, 1, {
              ts_id: ticketId,
              quantity: ticketsList[ticketIndex].quantity + ticket.quantity,
            });
          } else {
            ticketsList.push({
              ts_id: ticketId,
              quantity: ticket.quantity,
            });
          }
        });
      }
      // different countries
      if (cartCountry !== ticketsCountry) {
        ticketsList = map(ticketsList, (ticket) => {
          return {
            ts_id: ticket.ts_id,
            quantity: 0,
          };
        });
        forEach(tickets, (ticket) => {
          let ticketId = `${ticket.country}${ticket.ts_ticket_type_id}`;
          ticketsList.push({
            ts_id: ticketId,
            quantity: ticket.quantity,
          });
        });
      }
      ctx.commit("setTicketsList", ticketsList);
      await ctx.dispatch("updateCart");
    },
    async updateTicketsListItem(ctx, { ticket, quantity }) {
      let { ticketsList } = ctx.state;
      let ticketId = `${ticket.country}${ticket.ts_ticket_type_id}`;
      let ticketIndex = findIndex(ticketsList, { ts_id: ticketId });

      console.log(ticketId, ticketIndex);

      // remove && update
      if (ticketsList[ticketIndex]) {
        ticketsList[ticketIndex].quantity = quantity;
      }
      // add
      if (!ticketsList[ticketIndex]) {
        ticketsList.push({ ts_id: ticketId, quantity });
      }
      await ctx.dispatch("updateCart");
    },

    // checkout data
    async updateCartDetails(ctx) {
      let { checkoutBackup } = ctx.getters;
      // details
      await ctx.dispatch("updateCart", checkoutBackup);
    },
  },
  mutations: {
    setCart(state, cart) {
      state.cart = cart;
      if (cart && cart.length !== 0) {
        state.cartCountry = cart[0].country;
        state.ticketsList = map(cart, (item) => {
          return {
            ts_id: `${item.country}${item.ts_ticket_type_id}`,
            quantity: item.quantity,
          };
        });
      } else {
        state.cartCountry = null;
        state.ticketsList = [];
      }
    },
    setCartDetails(state, details) {
      state.cartDetails = details;
    },
    setTicketsList(state, list) {
      state.ticketsList = list;
    },
  },
  getters: {
    storedTickets: (state) => {
      const lookup = keyBy(state.cartDetails.tickets, "id");
      return map(state.cart, (ticket) =>
        merge(ticket, lookup[ticket.ts_ticket_type_id])
      );
    },
    cartDetails: (state) => state.cartDetails,
  },
};
