import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import { i18n } from "@/i18n";
import "@/common/filters";
import ActionCableVue from "actioncable-vue";
import Zendesk from "@/services/zendesk";
import '@splidejs/splide/dist/css/splide.min.css';
import vuetify from './plugins/vuetify'

Vue.use(ActionCableVue, {
  debug: true,
  debugLevel: "error",
  connectImmediately: false,
});

Vue.use(Cropper);

Vue.config.productionTip = false;

new Vue({
  router,

  provide: {
    Zendesk,
  },

  store,
  i18n,
  vuetify,
  render: (h) => h(App)
}).$mount("#app");
