<template>
  <div class="region-item">
    <!-- desktop list -->
    <div class="desktop-view">
      <div class="region-name">{{ region.region }}</div>
      <div class="region-countries">
        <div
          class="country-item"
          v-for="(country, i) in orderList(region.countries)"
          v-bind:key="i"
        >
          <div class="country-name cursor-pointer">
            <span>{{ country.country }}</span>
          </div>
          <div
            v-for="(lang, langIndex) in country.languages"
            v-bind:key="langIndex"
            class="country-lang cursor-pointer"
            @click="changeLocale(country.code, lang.code)"
          >
            {{ lang.language }}
          </div>
        </div>
      </div>
    </div>
    <!-- mobile list -->
    <div class="mobile-view">
      <div class="region-name cursor-pointer" @click="handleList()">
        <div>{{ region.region }}</div>
        <div class="icon-btn">
          <i v-if="!showCountries" class="material-icons">add</i>
          <i v-if="showCountries" class="material-icons">remove</i>
        </div>
      </div>
      <div class="region-countries" :id="'country-list-' + id">
        <div
          class="country-item"
          v-for="(country, i) in orderList(region.countries)"
          v-bind:key="i"
        >
          <div class="country-name cursor-pointer">
            <span>{{ country.country }}</span>
          </div>
          <div
            v-for="(lang, langIndex) in country.languages"
            v-bind:key="langIndex"
            class="country-lang cursor-pointer"
            @click="changeLocale(country.code, lang.code)"
          >
            {{ lang.language }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
export default {
  data: () => ({
    showCountries: false,
    // env: process.env.VUE_APP_ENV,
    // supportedLanguages: [
    //   "en",
    //   "fr",
    //   "de",
    //   "it",
    //   "es",
    //   "ja",
    //   "pt",

    //   "zh",
    //   "ko",
    //   "cs",
    //   "el",
    //   "nl",
    //   "ro",
    //   "sl",
    //   "pl",
    // ],
  }),
  props: ["region", "id"],
  computed: {
    ...mapGetters(["isAuthorized"]),
  },
  methods: {
    ...mapActions(["patchUserDataWithLocale", "getTranslations"]),
    handleList() {
      this.showCountries = !this.showCountries;
      let el = document.getElementById(`country-list-${this.id}`);
      if (this.showCountries) {
        el.style.maxHeight = el.scrollHeight + "px";
      } else {
        el.style.maxHeight = 0;
      }
    },
    async changeLocale(country, lang) {
      try {
        if (this.isAuthorized) {
          await this.patchUserDataWithLocale({
            language: lang,
            spartan_country_code: country,
          });
        } else {
          this.$router.push({
            name: this.$route.name,
            query: { lang, country },
          });
          this.$store.commit("updateUser", {
            language: lang,
            spartan_country_code: country,
          });
          await this.getTranslations(lang);
        }
      } catch (err) {
        console.log("Change region error", err);
      }

      this.$emit("language-change");
    },
    orderList(list) {
      return _.orderBy(list, ["country"]);
    },
  },
};
</script>
<style lang="scss" scoped>
// .hide-lang {
//   display: none;
// }
.region-item {
  margin-bottom: 18px;

  .desktop-view {
    display: block;
  }
  .mobile-view {
    display: none;
  }

  .region-name {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
  }
  .region-countries {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    padding: 24px 0;
    .country-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 30px;
      .country-name {
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        position: relative;
        span:before {
          content: "";
          position: absolute;
          height: 4px;
          top: -3px;
          left: 0;
          right: 0;
          background-color: #cf102d;
          width: 100%;
          max-width: 0;
          opacity: 0;
          transition: opacity 0.5s;
        }
      }
      .country-lang {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        text-transform: capitalize;
        width: 100%;
      }
      .country-lang:hover {
        color: #cf102d;
      }
    }
    .country-item:hover {
      .country-name {
        color: #cf102d;
        span:before {
          max-width: 100%;
          opacity: 1;
        }
      }
    }
  }
}
@media screen and (max-width: 1279px) {
  .region-item {
    margin-bottom: 0;
    .desktop-view {
      display: none;
    }
    .mobile-view {
      display: block;
    }
    .region-name {
      position: relative;

      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      text-transform: uppercase;
      padding: 22px 10px;

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .icon-btn {
        position: absolute;
        right: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        i {
          color: #000000;
        }
      }
    }
    .region-countries {
      grid-template-columns: 1fr 1fr;
      padding: 0 10px;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.2s ease-in-out;
      .country-item {
        // margin-bottom: 20px;
        padding-bottom: 20px;
        margin-top: 4px;
        .country-name {
          font-size: 12px;
          line-height: 19px;
        }
        .country-lang {
          font-size: 12px;
          line-height: 19px;
        }
      }
    }
  }
}
</style>
