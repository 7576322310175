<template>
  <a class="component-link-small" :href="specificData.href">
    {{ specificData.text }}
  </a>
</template>

<script>
import MenuItemResolverMixin from "@/components/menu/provision/MenuItemResolverMixin";
import {mapGetters} from "vuex";
import localizeResolver from "@/common/mixins/localizeResolver";
import {UrlPath} from "@/common/helpers";

export default {
  name: "componentLinkSmall",
  mixins: [ MenuItemResolverMixin, localizeResolver],
  computed: {
    ...mapGetters([
      "userData",
    ]),
    specificData() {
      let link = this.itemData.data?.fields?.url;
      if (UrlPath.isRelative(link)) {
        link = UrlPath.join(link, this.localizeConfig?.homeUrl);
      }
      return {
        text: this.itemData.text,
        href: link,
        originHref: this.itemData.data?.fields?.url,
        homeUrl: this.localizeConfig?.homeUrl,
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.component-link-small {
  display: inline-block;
  width: fit-content;
  position: relative;
  color: white;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  text-decoration: none;
  text-transform: initial;
  white-space: nowrap;


  &:active,
  &:visited {
    text-decoration: none;
    color: white;
  }

  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #fff;
    visibility: hidden;
    transition: all .3s ease-in-out;
  }

  &:hover {
    &:before {
      visibility: visible;
      width: 100%;
    }
  }

  ::v-deep {
    & + .component-link-big {
      margin-top: 38px;
    }
    & + .component-link-small
    {
      margin-top: 12px;
    }
    & + .component-mobile-app-link {
      margin-top: 36px;
    }
  }
}
</style>
