export default {
  //   Germany: {
  //     BW: "Baden-Wurttemberg",
  //     BY: "Bavaria",
  //     BE: "Berlin",
  //     BB: "Brandenburg",
  //     HB: "Bremen",
  //     HH: "Hamburg",
  //     HE: "Hessen",
  //     MV: "Mecklenburg-Vorpommern",
  //     NI: "Lower Saxony",
  //     NW: "North Rhine-Westphalia",
  //     RP: "Rhineland-Palatinate",
  //     SL: "Saarland",
  //     SN: "Saxony",
  //     ST: "Saxony-Anhalt",
  //     SH: "Schleswig-Holstein",
  //     TH: "Thuringia",
  //   },
  //   Switzerland: {
  //     AG: "Aargau",
  //     AI: "Appenzell Innerrhoden",
  //     AR: "Appenzell Ausserrhoden",
  //     BE: "Berne",
  //     BL: "Basel-Country",
  //     BS: "Basel-City",
  //     FR: "Fribourg",
  //     GE: "Geneva",
  //     GL: "Glarus",
  //     GR: "Grisons",
  //     JU: "Jura",
  //     LU: "Lucerne",
  //     NE: "Neuchtel",
  //     NW: "Nidwalden",
  //     OW: "Obwalden",
  //     SG: "St. Gallen",
  //     SH: "Schaffhausen",
  //     SO: "Solothurn",
  //     SZ: "Schwyz",
  //     TG: "Thurgau",
  //     TI: "Ticino",
  //     UR: "Uri",
  //     VD: "Vaud",
  //     VS: "Valais",
  //     ZG: "Zug",
  //     ZH: "Zurich",
  //   },
  //   "United Kingdom": {
  //     BDF: "Bedfordshire",
  //     BRK: "Berkshire",
  //     BKM: "Buckinghamshire",
  //     CAM: "Cambridgeshire",
  //     CHS: "Cheshire",
  //     CLV: "Cleveland",
  //     DUR: "Co. Durham",
  //     CON: "Cornwall",
  //     CUL: "Cumberland",
  //     CMA: "Cumbria",
  //     DBY: "Derbyshire",
  //     DEV: "Devon",
  //     DOR: "Dorset",
  //     ERY: "East Riding of Yorkshire",
  //     SXE: "East Sussex",
  //     ESS: "Essex",
  //     GLS: "Gloucestershire",
  //     GTM: "Greater Manchester",
  //     HAM: "Hampshire",
  //     HWR: "Hereford and Worcester",
  //     HEF: "Herefordshire",
  //     HRT: "Hertfordshire",
  //     HUM: "Humberside",
  //     HUN: "Huntingdonshire",
  //     IOW: "Isle of Wight",
  //     KEN: "Kent",
  //     LAN: "Lancashire",
  //     LEI: "Leicestershire",
  //     LIN: "Lincolnshire",
  //     MSY: "Merseyside",
  //     NFK: "Norfolk",
  //     NRY: "North Riding of Yorkshire",
  //     NYK: "North Yorkshire",
  //     NTH: "Northamptonshire",
  //     NBL: "Northumberland",
  //     NTT: "Nottinghamshire",
  //     OXF: "Oxfordshire",
  //     RUT: "Rutland",
  //     SAL: "Shropshire",
  //     SOM: "Somerset",
  //     SYK: "South Yorkshire",
  //     STS: "Staffordshire",
  //     SFK: "Suffolk",
  //     SRY: "Surrey",
  //     SSX: "Sussex",
  //     TWR: "Tyne and Wear",
  //     WAR: "Warwickshire",
  //     WMD: "West Midlands",
  //     WRY: "West Riding of Yorkshire",
  //     SXW: "West Sussex",
  //     WYK: "West Yorkshire",
  //     WES: "Westmorland",
  //     WIL: "Wiltshire",
  //     WOR: "Worcestershire",
  //     YKS: "Yorkshire",
  //   },
  US: {
    AA: "Armed Forces Americas",
    AE: "Armed Forces Europe",
    AL: "Alabama",
    AK: "Alaska",
    AP: "Armed Forces Pacific",
    AZ: "Arizona",
    AR: "Arkansas",
    CA: "California",
    CO: "Colorado",
    CT: "Connecticut",
    DE: "Delaware",
    DC: "District Of Columbia",
    FL: "Florida",
    GA: "Georgia",
    HI: "Hawaii",
    ID: "Idaho",
    IL: "Illinois",
    IN: "Indiana",
    IA: "Iowa",
    KS: "Kansas",
    KY: "Kentucky",
    LA: "Louisiana",
    ME: "Maine",
    MD: "Maryland",
    MA: "Massachusetts",
    MI: "Michigan",
    MN: "Minnesota",
    MS: "Mississippi",
    MO: "Missouri",
    MT: "Montana",
    NE: "Nebraska",
    NV: "Nevada",
    NH: "New Hampshire",
    NJ: "New Jersey",
    NM: "New Mexico",
    NY: "New York",
    NC: "North Carolina",
    ND: "North Dakota",
    OH: "Ohio",
    OK: "Oklahoma",
    OR: "Oregon",
    PA: "Pennsylvania",
    RI: "Rhode Island",
    SC: "South Carolina",
    SD: "South Dakota",
    TN: "Tennessee",
    TX: "Texas",
    UT: "Utah",
    VT: "Vermont",
    VA: "Virginia",
    WA: "Washington",
    WV: "West Virginia",
    WI: "Wisconsin",
    WY: "Wyoming",
  },
  CA: {
    AB: "Alberta",
    BC: "British Columbia",
    MB: "Manitoba",
    NB: "New Brunswick",
    NL: "Newfoundland and Labrador",
    NT: "Northwest Territories",
    NS: "Nova Scotia",
    NU: "Nunavut",
    ON: "Ontario",
    PE: "Prince Edward Island",
    QC: "Quebec",
    SK: "Saskatchewan",
    YT: "Yukon",
  },
  AU: {
    "AU-ACT": "Australian Capital Territory",
    "AU-NSW": "New South Wales",
    "AU-NT": "Northern Territory",
    "AU-QLD": "Queensland",
    "AU-SA": "South Australia",
    "AU-TAS": "Tasmania",
    "AU-VIC": "Victoria",
    "AU-WA": "Western Australia",
  },
};
