export default {
  showTropaion: false, // "tropaion" route access
  apiGate: "https://api-gate.eks.spartan.com",
  apiAuth: "https://api-gate.eks.spartan.com/auth",
  apiAccount: "https://api-gate.eks.spartan.com/account",
  apiSportograf: "https://sportograf.eks.spartan.com",
  socketConnectionUrl: "wss://delphi-socket.eks.spartan.com/cable",
  api: "",
  homePage: "https://www.spartan.com",
  searchUrl: "https://spartan.com/search",
  shopUrl: "https://spartan.com/cart",
  findRaceUrl: "http://race.spartan.com/en/race/find-race",
  authPage: "https://race.spartan.com/en",
  ordersUrl: "https://www.spartan.com/account",
  headerLinksApi: "https://race.spartan.com/api/content/unified_nav_prod",
  stripe: {
    key: "pk_live_51IvQkSB6KZeYGkRuspBgT3qi0H1Js87clekUkDbvPRgorYffRyJiuSbksZ13ht2mZ4blqstp1odaGArumwF9hUpd00nnD94Fzx",
    keys: {
      us: "pk_live_ZgwxRNRM3TaFvWcZQQ2UzndO",
      eu: "pk_live_51KRDOeHCmeTuP7e7Cf4sqSBzXqWOJwQhUvmh3jFjutt5CGVKAx7aZpOkFB0cBsncJnuviZBJAdbtPfmCYvfeUafG00emcHcd4e",
      gb: "pk_live_G7eOkE78ihet9tq8XW264vuz007ppGZZ3A",
    },
    yearly: {
      productId: "prod_I4JxLGEXT3oHa5",
      priceId: "price_1OrpLNB6KZeYGkRuEn2hWyFb",
    },
    monthly: {
      productId: "prod_J7radNRzgDsQji",
      priceId: "price_1OrpL7B6KZeYGkRubsY2A3c9",
    },
  },
  checkout: {
    us: {
      spartanFoundationQuestionId: "10",
      // spartanPlusTicket: {
      //   ticketTypeId: "17750",
      //   eventId: "1659",
      // },
      refundProtectVendorCode: "ven_local_cabefb8a92e144fb808d311bee1cdd72",
    },
    ca: {
      refundProtectVendorCode: "ven_local_ccac19b6c25f4564ae06e718fdc87e4e",
    },
    eu: {
      spartanFoundationQuestionId: "125",
      refundProtectVendorCode: "ven_local_7e78dca40a7f4af1ad958731b4e80b37",
    },
    gb: {
      refundProtectVendorCode: "ven_local_ab4827e435774795858ecd3176dff1fa",
    },
    ch: {
      spartanFoundationQuestionId: "48",
    },
    in: {
      spartanFoundationQuestionId: "25",
    },
  },
  sezzlePublicKey: "sz_pub_IgylB0JhDqkvroYwcXx62CKoD1KVAfjK",
  sezzleConfig: {
    mode: "iframe",
    publicKey: "sz_pub_IgylB0JhDqkvroYwcXx62CKoD1KVAfjK",
    apiMode: "live",
    isVirtualCard: true,
  },
  contentful: {
    spaceID: "1bk7n9wtl2gh",
    sysId: "3URvV3SgbC5ukzWlczJapD",
    environment: "nuxt-dev",
    serviceToken:
      "f3426e9333619474060f9862eb4271f221641bfbf08cb4be103e0cd8dbbf7ff2",
  },
};
