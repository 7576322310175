let instance

const HtmlElId = 'ze-snippet'

export class ZendeskCore {
  id = HtmlElId;

  constructor() {
    if (instance) {
      return instance
    }

    instance = this;
  }

  /**
   * Init singleton snippet
   * @param {string} key - service key for initialization
   */
  init(key = '') {
    // console.log('call init', key, this.currentKey());
    if (ZendeskCore.checkInit() && !this.checkKey(key)) {
      if (window.zE) {
        zE('webWidget', 'hide')
      }
      this.destroy()
      setTimeout(() => {
        this.initScript(key)
        if (window.zE) {
          zE('webWidget', 'reset')
          zE('webWidget', 'show')
        }
      }, 500)
    } else {
      this.initScript(key)
    }
  }

  /**
   * Static getter initialized element
   * @return {HTMLElement}
   */
  static getElement() {
    return document.getElementById(HtmlElId);
  }

  /**
   * Helper for check initialization
   * @return {Boolean}
   */
  static checkInit() {
    return !!ZendeskCore.getElement()
  }

  fillUserData(userData) {
    if (window.zE) {
      window.zE("webWidget", "prefill", {
        email: {
          value: userData.email,
          readOnly: true
        },
        name: {
          value: userData.first_name + " " + userData.last_name,
          readOnly: true
        }
      });
    }
  }

  /**
   * Helper checker if need re-initialization
   * @param key
   * @return {boolean}
   */
  checkKey(key) {
    return key === this.currentKey()
  }

  /**
   * Getter initialized key
   * @return {string|null}
   */
  currentKey() {
    const el = ZendeskCore.getElement()
    if (!el) return null
    try {
      const targetUrl = new URL(el.src)
      return targetUrl.searchParams.get('key')
    } catch (e) {
      return null
    }
  }

  /**
   * Getter key for initialization. Default: Env variable
   * @return {string}
   */
  get key() {
    return this.currentKey() || process.env.VUE_APP_ZENDESK_SERVICE_KEY
  }

  /**
   * Initialize Script
   * Paste initialize code here
   */
  initScript(key) {
    // * Paste initialize code here from official documentation
    /* eslint-disable */
    (function(w, d, id, key) {
      const f = d.getElementById('end-external-libraries');
      const j = d.createElement('script');
      j.async = true;
      j.id = id;
      j.src = `https://static.zdassets.com/ekr/snippet.js?key=${key}`;
      f.parentNode.insertBefore(j, f);
    })(window, document, this.id, key || this.key);
    /* eslint-enable */
  }

  /**
   * Destroy initialized element
   */
  destroy() {
    // delete inited objects
    delete window.zEWebpackACJsonp;
    delete window.zESettings;
    delete window.zE;
    delete window.zEACLoaded;
    const el = ZendeskCore.getElement()
    if (el) {
      el.remove()
    }
  }
}

export const Zendesk = new ZendeskCore();

export default Zendesk
