// import cardImage1 from "@/assets/member_deals_card_image1.png";
// import cardImage2 from "@/assets/member_deals_card_image2.png";
// import cardImage3 from "@/assets/member_deals_card_image3.png";
// import cardImage4 from "@/assets/member_deals_card_image4.png";
// import cardImage5 from "@/assets/member_deals_card_image5.png";
import cardImage6 from "@/assets/member_deals_card_image6.png";
import cardImage7 from "@/assets/member_deals_card_image7.png";
// import cardImage8 from "@/assets/member_deals_card_image8.png";
// import cardImage9 from "@/assets/member_deals_card_image9.png";

export const MEMBER_DEALS_LIST = [
  // {
  //   id: 0,
  //   description:
  //     "Tribit's Strombox portable speaker is the perfect companion to any outdoor activity.",
  //   promoCode: "TBSPSBMC",
  //   cardImage: cardImage1,
  //   shopLink: "https://tribit.com/products/tribit-stormbox-portable-speaker/",
  // },
  // {
  //   id: 1,
  //   description:
  //     "Tribit's Wireless earbuds may just become your new favorite gym buddy.",
  //   promoCode: "TBSPSBMC",
  //   cardImage: cardImage2,
  //   shopLink:
  //     "https://tribit.com/products/tribit-movebuds-h1-wireless-earbuds/",
  // },
  // {
  //   id: 2,
  //   description:
  //     "Featured at Spartan+ Recovery tents you can get your very own from Sportaneer.",
  //   promoCode: "SBND9MSG",
  //   cardImage: cardImage3,
  //   shopLink:
  //     "https://sportneer.com/products/sportneer-elite-d9-percussive-massage-gun/",
  // },
  // {
  //   id: 3,
  //   description:
  //     "Sportaneer offers a few different massage gun models to suit any athletes needs.",
  //   promoCode: "SBND9MSG",
  //   cardImage: cardImage4,
  //   shopLink:
  //     "https://sportneer.com/products/sportneer-k1-percussive-massage-gun/",
  // },
  // {
  //   id: 4,
  //   description:
  //     "Escape the elements and bring your bike indoors with Sportaneers Bike Trainer Stand.",
  //   promoCode: "SBND9MSG",
  //   cardImage: cardImage5,
  //   shopLink:
  //     "https://sportneer.com/products/sportneer-magnetic-bike-trainer-stand/",
  // },
  {
    id: 5,
    description:
      "5.11 makes purpose-built technical apparel and gear for life's most demanding missions.",
    promoCode: "SPARTAN+15",
    cardImage: cardImage6,
    shopLink: "https://www.511tactical.com/",
  },
  {
    id: 6,
    description:
      "Make your race day photo's a work of art with TopShelf canvas prints.",
    promoCode: "SAVE15",
    cardImage: cardImage7,
    shopLink: "https://topshelfprints.com/pages/spartan",
  },
  // {
  //   id: 7,
  //   description:
  //     "Get a personalized health report from our Official Healthcare Provider, Wild Health.",
  //   promoCode: "SPARTANPLUS",
  //   cardImage: cardImage8,
  //   shopLink:
  //     "https://www.wildhealth.com/?utm_source=spartan&utm_medium=spartanplusnl4_6",
  // },
  // {
  //   id: 8,
  //   description:
  //     "Get 1 year free of Heroic, the official wellness app of Spartan.",
  //   promoCode: "HEROICSPARTANJAN",
  //   cardImage: cardImage9,
  //   shopLink:
  //     "https://www.heroic.us/optimize/spartan?utm_source=spartan&utm_medium=Spartan%2B&utm_campaign=accountpage",
  // },
];
