import { getCensoredWords } from "@/common/helpers";

export function censorValidator(value, completed) {
  return !getCensoredWords(value, completed).length;
}

export function requireValidator(value) {
  return !!value;
}

export function emailValidator(value) {
  const pattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return pattern.test(value);
}

export function lettersValidator(value) {
  const pattern = /^[A-Za-z\s]+$/;
  return pattern.test(value);
}

export function numbersValidator(value) {
  // const pattern = /^\d+$/;
  const pattern = /^(\s*|\d+)$/; // numbers or empty string
  return pattern.test(value);
}

export function domainValidator(value, rootDomain = "") {
  if (!value) return true;
  const domain = rootDomain || "[a-z]{1,63} | xn--[a-z0-9]{1,59}";
  const pattern = `^(?<protocol>(?:https?:)?\\/\\/)?(?<subdomains>[\\w|-]+[\\.])*(?<domain>${domain})(?<path>\\/[\\w|-|_]+)*(?<query>\\?[\\w|&|=|.|-|_|\\[|\\]|$]*)?`;

  const regex = new RegExp(pattern, "gim");

  return regex.test(value);
}

export default {
  censorValidator,
  requireValidator,
  emailValidator,
  domainValidator,
  numbersValidator,
};
