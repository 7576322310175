import local from "./local.js";
import staging from "./staging.js";
import production from "./production.js";

const config = {
  local,
  staging,
  production
};

export default config[process.env.VUE_APP_ENV] || config.local;
