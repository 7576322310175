export default {
  setCurrentTicket(state, payload) {
    state.currentTicket = payload;
  },
  setInviteTeamInfo(state, payload) {
    state.inviteTeamInfo = payload;
  },
  setStoredTickets(state, tickets) {
    state.storedTickets = tickets;
  },
  setAttendees(state, payload) {
    state.attendees = payload;
  },
};
