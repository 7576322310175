import Vue from "vue";
import { lowerCase } from "lodash";
import { censorValidator } from "@/common/validators";
import { CURRENCIES } from "@/lists";
import store from "@/store";

Vue.filter("censor", (value) => {
  if (!value) return "";
  const censoredWords = censorValidator(value, false);
  if (censoredWords.length > 0) {
    let result = value;
    for (const word of censoredWords) {
      const replace = Array.from({ length: word.length }, () => "*").join("");
      result = result.replace(word, replace);
    }
    return result;
  }
  return value;
});

Vue.filter("currencyLegacy", (value, locale = "en-US") => {
  if (!value) return "";

  const currencyFormatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  return currencyFormatter.format(value);
});

// TODO: Make it generic
Vue.filter("currency", (value, region, currency) => {
  let { userData, checkoutRegion } = store.getters;

  if (region && currency) {
    let locale = CURRENCIES[region]?.language[userData.language] || "en-US";
    let currencyFormatter = new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currency,
    });

    return currencyFormatter.format(value);
  }

  // Checkout currency filter
  let locale =
    CURRENCIES[lowerCase(checkoutRegion)]?.language[userData.language] ||
    "en-US";

  const currencyFormatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency:
      localStorage.getItem("checkoutCurrency") ||
      CURRENCIES[lowerCase(checkoutRegion)]?.currency ||
      "USD",
    // minimumFractionDigits: 0,
    // maximumFractionDigits: 2,
  });

  return currencyFormatter.format(value);
});

// TODO: Need to destroy this filter
Vue.filter("truncate", (text, length = 300, clamp = "...") => {
  const limit = Math.max(0, length - 3);
  if (text.length < limit) return text;
  return text.slice(0, limit) + clamp;
});

Vue.filter("capitalize", (value, options) => {
  if (value && isNaN(value)) {
    if (options === "first") {
      return value[0].toUpperCase() + value.substr(1);
    } else if (!options) {
      value = value.split(" ");
      for (let i = 0; i < value.length; i++) {
        value[i] = value[i][0].toUpperCase() + value[i].substr(1);
      }
      return value.join(" ");
    }
  }
  return value;
});
