import { toUpper } from "lodash";
export default {
  state: {
    resultsList: [],
  },
  actions: {
    async getAddress(ctx, query = "234 congress street") {
      let res = await fetch(
        `https://api.opencagedata.com/geocode/v1/json?q=${query}&language=en&key=3f927ce9bcd24c28b9324357aa7d7650`
      );
      let result = await res.json();
      // console.log("Store action: get address:", result);
      ctx.commit("setResultsList", result.results);
    },
  },
  mutations: {
    setResultsList: (state, list) => {
      state.resultsList = list.map((item) => {
        let { components, formatted, geometry } = item;
        return {
          formatted,
          ...components,
          geometry,
          country_code: toUpper(components.country_code),
        };
        // const formatted = [];
        // if (components._type === "country") {
        //   formatted.push(components.country);
        // } else {
        //   if (components.city) {
        //     formatted.push(components.city);
        //   }
        //   if (components.state) {
        //     formatted.push(components.state);
        //   }
        //   if (components.country) {
        //     formatted.push(components.country);
        //   }
        // }
        // return { formatted: formatted.join(", "), ...components };
      });
    },
  },
  getters: {
    geocodingResults: (state) => {
      return state.resultsList;
    },
  },
};
