<template>
  <v-avatar
    class="user-avatar"
    :class="{ 'is-empty': isAuthorized && !src, 'is-avatar': src }"
    :size="size"
    v-bind="$attrs"
  >
    <img
      v-if="src"
      :src="src"
      class="avatar-image"
      @error="fallbackAvatar"
      alt="avatar"
    />
    <span v-else-if="isAuthorized" class="name-letter">{{ firstLetter }}</span>
    <img
      v-else
      src="./../assets/icon-user.svg"
      class="avatar-icon"
      alt="avatar"
    />
  </v-avatar>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "UserAvatar",
  props: {
    size: {
      type: Number,
      default: 19,
    },
  },
  data() {
    return {
      src: "",
    };
  },
  computed: {
    ...mapGetters(["isAuthorized", "userData", "userPic"]),
    firstLetter() {
      return this.userData.first_name?.charAt(0);
    },
  },
  methods: {
    fallbackAvatar() {
      this.src = null;
    },
  },
  watch: {
    userPic: {
      immediate: true,
      handler() {
        this.src = this.userPic.small;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.user-avatar {
  overflow: hidden;
  box-sizing: border-box;

  .name-letter {
    font-size: 12px;
    line-height: 14px;
    font-weight: bold;
    text-transform: uppercase;
    color: #000000;
  }

  &.is-empty {
    background: white;
    border: 1px solid white;
  }
  &.is-avatar {
    border: 1px solid white;
  }
}
</style>
