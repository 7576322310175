import { mapGetters, mapActions } from "vuex";
import { sumBy } from "lodash";
import { TS_CHECKOUT_COUNTRIES } from "@/common/const";

export default {
  computed: {
    ...mapGetters(["storedTickets", "userData", "isAuthorized"]),
    ticketsCountry() {
      return this.storedTickets[0]?.country?.toLowerCase();
    },
    useAccountCheckout() {
      return TS_CHECKOUT_COUNTRIES.includes(this.ticketsCountry);
    },
    tsCheckoutLink() {
      return `https://tickets-${this.ticketsCountry}.spartan.com/api/shoppingCart/add/?goToCheckout=1${this.ticketsQueryParams}`;
    },
    ticketsQueryParams() {
      let params = "";
      this.storedTickets.forEach((ticket) => {
        params =
          params +
          `&cartTasks[ticketTypeId_${ticket.ts_ticket_type_id}][typeId]=${ticket.ts_ticket_type_id}&cartTasks[ticketTypeId_${ticket.ts_ticket_type_id}][eventId]=${ticket.ts_event_id}&cartTasks[ticketTypeId_${ticket.ts_ticket_type_id}][quantity]=${ticket.quantity}`;
      });
      return params;
    },
    ticketsCount() {
      return sumBy(this.storedTickets, "quantity");
    },
  },
  methods: {
    ...mapActions(["deleteCart"]),
    goToCheckout() {
      if (!this.isAuthorized)
        return this.$router.push({
          name: "formLogin",
          query: {
            redirect: `${window.location.origin}/${this.ticketsCountry}/checkout`,
            lang: this.userData.language || "en",
            country: this.userData.spartan_country_code || "US",
          },
        });

      this.$router.push({
        name: "checkout",
        params: { region: this.ticketsCountry },
      });
    },
    async clearCart() {
      console.log("Clear tickets card");
      await this.deleteCart();
    },
  },
};
