import { mapGetters } from "vuex";
import { Localizer } from "@/common/helpers";

export default {
  computed: {
    ...mapGetters(["userData"]),
    userCountry() {
      return this.userData?.spartan_country_code?.toUpperCase();
    },
    userLang() {
      return this.userData?.language || "en";
    },
    userLocale() {
      return `${this.userLang}-${this.userCountry}`;
    },
    countriesConfig() {
      return Localizer.getCountiesConfig();
    },
    localizeConfig() {
      return this.countriesConfig[this.userCountry]?.lang[this.userLang];
    },
  },
  methods: {
    loadLocalizeParams() {
      const resultLocalizeConfig = {
        lang: this.userData?.language,
        country: this.userData?.spartan_country_code,
      };
      // check localize config
      if (!resultLocalizeConfig.lang || !resultLocalizeConfig.country) {
        // resolve lang
        const { lang, country } = this.$route.query;

        const locale = Localizer.resolveLocale(country, lang);

        resultLocalizeConfig.lang = locale.lang;
        resultLocalizeConfig.country = locale.country;
      }
      this.$store.commit("updateUser", {
        language: resultLocalizeConfig.lang,
        spartan_country_code: resultLocalizeConfig.country,
      });
    },
  },
};
