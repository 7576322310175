<template>
  <div class="component-menu-promo">
    <a class="promo-image-wrapper">
      <component-menu-image
        :item="specificData.image"
        :references="references"
        class="promo-image"
      />
    </a>
    <div class="promo-content">
      <div class="promo-text">
        {{ specificData.text }}
      </div>
      <a class="promo-cta" :href="specificData.linkUrl">
        {{ specificData.linkText }}
      </a>
    </div>
  </div>
</template>

<script>
import MenuItemResolverMixin from "@/components/menu/provision/MenuItemResolverMixin";
import ComponentMenuImage from "@/components/menu/content-parts/componentMenuImage";

export default {
  name: "componentMenuPromo",
  components: { ComponentMenuImage },
  mixins: [MenuItemResolverMixin],
  computed: {
    specificData() {
      return {
        text: this.itemData.text || this.itemData.title,
        linkUrl: this.itemData.data?.fields?.linkUrl,
        linkText: this.itemData.data?.fields?.linkText,
        image: this.itemData.data?.fields?.image,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.component-menu-promo {
  display: inline-flex;
  align-items: center;
  flex-wrap: nowrap;
  position: relative;
  max-height: 100px;
  cursor: auto;
  margin-bottom: 40px;
  @media screen and (max-width: 1279px) {
    margin-bottom: 0;
    margin-top: 20px;
    align-items: flex-start !important;
    justify-content: flex-start !important;
  }

  .promo-image-wrapper {
    flex: 0 0 80px;
    width: 80px;
    margin-right: 30px;

    .promo-image {
      height: 100px;
      object-fit: cover;
      object-position: center;
      max-width: 100%;
    }
  }

  .promo-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;

    .promo-text {
      font-size: 14px;
      line-height: 21px;
      font-weight: 700;
      text-transform: initial;
      text-decoration: none;
    }
    .promo-cta {
      display: inline-block;
      width: fit-content;
      position: relative;
      color: white;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 21px;
      text-decoration: none;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      margin-top: 15px;

      &:active,
      &:visited {
        text-decoration: none;
        color: white;
      }

      &:before {
        content: "";
        position: absolute;
        width: 0;
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: #fff;
        visibility: hidden;
        transition: all 0.3s ease-in-out;
      }

      &:hover {
        &:before {
          visibility: visible;
          width: 100%;
        }
      }
    }
  }
}
</style>
