import { chain } from "lodash";
import { getCookie } from "../../cookie.js";
import config from "@/config";

const API = config.apiGate;

export default {
  state: {
    stripeProducts: [],
    activeStripeProduct: {},
    showBanner: true,
    platformsList: [],
    selectedPlatform: {},
  },
  actions: {
    async stripeGetProducts(ctx, data = {}) {
      let { productId, code } = data;
      let query = "";
      if (productId && code)
        query = `?code=${code}&current_product=${productId}`;

      const authorization = getCookie("user");
      const res = await fetch(`${API}/account/stripe/products${query}`, {
        method: "GET",
        headers: new Headers({
          // "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: authorization,
        }),
      });
      if (!res.ok) {
        return Promise.reject({
          status: res.status,
          text: "There is an error while trying to get Stripe products",
        });
      }
      let result = await res.json();
      // if (productId && code) {
      //   ctx.commit("setStripeProducts", result.products);
      //   return result;
      // }

      ctx.commit("setStripeProducts", result.products);
      // ctx.commit("setActiveStripeProduct", head(result.products));
      console.log("Subscription plans", result);
      return result;
    },
    async stripeAddUser(ctx) {
      const authorization = getCookie("user");
      const patchData = {
        athlete_user: ctx.getters.userData,
      };
      const res = await fetch(`${API}/account/athlete_users/payment_customer`, {
        method: "PATCH",
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: authorization,
        }),
        body: JSON.stringify(patchData),
      });

      if (!res.ok) {
        return Promise.reject({
          status: res.status,
          text: "There is an error while trying to add new user to Stripe.",
        });
      }
      ctx.dispatch("getUserData");
    },
    createSubscription(ctx, data) {
      const authorization = getCookie("user");
      let postData = {};

      postData.price_id = data.price_id;
      if (data.payment_method_id)
        postData.payment_method_id = data.payment_method_id;
      if (data.promotion_code) postData.promotion_code = data.promotion_code;

      return new Promise((resolve, reject) => {
        fetch(`${API}/account/stripe/subscriptions`, {
          method: "POST",
          headers: new Headers({
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: authorization,
          }),
          body: JSON.stringify(postData),
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            ctx.dispatch("getUserData");
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async checkCode(ctx, data) {
      const authorization = getCookie("user");
      const res = await fetch(
        `${API}/account/stripe/promotion_codes/${data.code}/check?product_id=${data.product_id}`,
        {
          method: "GET",
          headers: new Headers({
            // "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: authorization,
          }),
        }
      );
      if (!res.ok) {
        const response = await res.json();
        return Promise.reject({
          status: res.status,
          ...response,
        });
      }
      const checkResult = await res.json();

      await ctx.dispatch("stripeGetProducts", {
        productId: data.product_id,
        code: data.code,
      });
      // console.log(checkResult, "res");
      return checkResult;
    },
    async getCustomerPortalLink() {
      const authorization = getCookie("user");
      const res = await fetch(`${API}/account/stripe/subscriptions/portal`, {
        method: "GET",
        headers: new Headers({
          // "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: authorization,
        }),
      });
      const link = await res.json();
      return link;
    },
    closeBanner(ctx) {
      ctx.commit("hideBanner");
    },

    // ---- Unbreakable pass actions ----

    async getPassPlatforms(ctx) {
      const authorization = getCookie("user");
      const res = await fetch(`${API}/account/upass_registration_platforms`, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: authorization,
        }),
      });
      if (!res.ok)
        return Promise.reject(
          `Get pass platforms error. Status: ${res.status}`
        );

      const result = await res.json();
      ctx.commit("setPlatformsList", result.registration_platforms);
    },
    async getPassPlatformDetails(ctx, { platformId, lang }) {
      const authorization = getCookie("user");
      const res = await fetch(
        `${API}/account/upass_registration_platforms/${platformId}?lang=${lang}`,
        {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: authorization,
          }),
        }
      );
      if (!res.ok)
        return Promise.reject(
          `Get pass platforms error. Status: ${res.status}`
        );

      const result = await res.json();
      ctx.commit("setSelectedPlatform", result);
    },
    async sendPassAccessRequest(ctx, { platformId, lang }) {
      const authorization = getCookie("user");
      const res = await fetch(
        `${API}/account/upass_registration_platforms/${platformId}/access_request`,
        {
          method: "POST",
          headers: new Headers({
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: authorization,
          }),
        }
      );
      if (!res.ok)
        return Promise.reject(
          `Get pass platforms error. Status: ${res.status}`
        );

      const result = await res.json();
      console.log(result, "POST result");
      await ctx.dispatch("getPassPlatformDetails", { platformId, lang });
    },
  },
  mutations: {
    setStripeProducts: (state, products) => {
      state.stripeProducts = products;
    },
    setActiveStripeProduct: (state, product) => {
      state.activeStripeProduct = product;
    },
    hideBanner(state) {
      state.showBanner = false;
    },
    setPlatformsList(state, platforms) {
      state.platformsList = platforms;
    },
    setSelectedPlatform(state, platform) {
      state.selectedPlatform = platform;
    },
  },
  getters: {
    stripeProducts: (state) => state.stripeProducts,
    activeStripeProduct: (state) => state.activeStripeProduct,
    showSubscriptionCard(state) {
      return state.showBanner;
    },
    passPlatforms(state) {
      return chain(state.platformsList)
        .groupBy("brand")
        .map((value, key) => ({ brand: key, countries: value }))
        .orderBy("brand", "asc")
        .value();
    },
    selectedPlatformData(state) {
      return state.selectedPlatform;
    },
  },
};
