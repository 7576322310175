import countriesList from "./countries.js";
import statesList from "./states.js";
import regionsList from "./regions.js";
import domainsList from "./domains.js";
import { NATIONALITIES } from "./nationalities.js";
import { PHONE_CODES } from "./phoneCodes.js";
import { MEMBER_DEALS_LIST } from "./memberDeals.js";
import { MEMBER_BENEFITS_LIST } from "./memberBenefits.js";
import { CHECKOUT_CONFIG } from "./checkoutConfig.js";

import { CURRENCIES } from "./currencies.js";
import { CREDIT_CARDS } from "./creditCards.js";

export const countries = Object.keys(countriesList).map((key) => {
  return {
    id: key,
    value: countriesList[key],
  };
});

export const states = Object.keys(statesList).map((key) => {
  return {
    name: key,
    [key]: Object.keys(statesList[key]).map((stateCode) => {
      return {
        id: stateCode,
        value: statesList[key][stateCode],
      };
    }),
  };
});

export const regions = regionsList;
export const domains = domainsList;
export const nationalities = NATIONALITIES;
export const phoneCodes = PHONE_CODES;
export const memberDealsList = MEMBER_DEALS_LIST;
export const memberBenefitsList = MEMBER_BENEFITS_LIST;
export { CURRENCIES, CREDIT_CARDS, CHECKOUT_CONFIG };
