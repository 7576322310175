<template>
  <div class="toolbar">
    <div class="toolbar-main">
      <div class="toolbar-links" v-if="headerLinks">
        <header-menu />
      </div>
      <div class="toolbar-sides">
        <a :href="headerConfig.homeUrl" class="toolbar-logo cursor-pointer">
          <div class="logo-circle"></div>
          <img src="./../assets/spartan_logo.svg" alt="logo" />
        </a>
        <div class="profile-tools">
          <tickets-cart-dropdown />
          <div class="toolbar-buttons">
            <a
              v-if="headerConfig.actionButton"
              :href="headerConfig.actionButton"
              class="mem-btn btn-primary"
            >
              {{ $t("find_race") }}
            </a>
          </div>
          <div class="subline-elements">
            <a :href="headerConfig.searchLink" v-if="showSearchLink">
              <img src="./../assets/search.svg" alt="search" />
            </a>
            <a :href="headerConfig.shopLink">
              <i class="material-icons">shopping_cart</i>
            </a>
          </div>
          <div class="sidebar-elements">
            <a :href="headerConfig.shopLink">
              <img src="./../assets/shopping_basket.svg" alt="shop" />
            </a>
            <div
              class="open-sidebar-btn cursor-pointer"
              :class="{ collapsed: !showSidebar }"
              @click="openSidebar"
            >
              <div class="menu-line"></div>
              <div class="menu-line"></div>
              <div class="menu-line"></div>
            </div>
          </div>
          <!-- Profile menu -->
          <div class="main-menu-item cursor-pointer">
            <user-avatar />
            <div class="dropdown-menu" ref="dropdown">
              <div class="menu-content">
                <div
                  v-if="showHomeLink"
                  class="dropdown-item"
                  @click="openView('home')"
                >
                  <div class="item-link">
                    <span class="line-section">
                      <span>{{ $t("home") }}</span>
                      <div class="item-line"></div>
                    </span>
                  </div>
                </div>
                <div
                  v-if="isAuthorized"
                  class="dropdown-item"
                  @click="openView('events')"
                >
                  <div class="item-link">
                    <span class="line-section">
                      <span>{{ $t("events") }}</span>
                      <div class="item-line"></div>
                    </span>
                  </div>
                </div>
                <div
                  v-if="isAuthorized"
                  class="dropdown-item"
                  @click="openView('results')"
                >
                  <div class="item-link">
                    <span class="line-section">
                      <span>{{ $t("results") + " & " + $t("Photos") }}</span>
                      <div class="item-line"></div>
                    </span>
                  </div>
                </div>
                <div
                  v-if="isAuthorized"
                  class="dropdown-item"
                  @click="openView('teamsList')"
                >
                  <div class="item-link">
                    <span class="line-section">
                      <span>{{ $t("teams") }}</span>
                      <div class="item-line"></div>
                    </span>
                  </div>
                </div>
                <div
                  v-if="isAuthorized && showTropaionLink"
                  class="dropdown-item"
                  @click="openView('tropaion')"
                >
                  <div class="item-link">
                    <span class="line-section">
                      <span>{{ $t("tropaion") }}</span>
                      <div class="item-line"></div>
                    </span>
                  </div>
                </div>
                <div
                  v-if="isAuthorized"
                  class="dropdown-item"
                  @click="openView('benefits')"
                >
                  <div class="item-link">
                    <span class="line-section">
                      <span>{{ $t("benefits") }}</span>
                      <div class="item-line"></div>
                    </span>
                  </div>
                </div>
                <a class="dropdown-item" :href="headerConfig.ordersLink">
                  <div class="item-link">
                    <span class="line-section">
                      <span>{{ $t("orders") }}</span>
                      <div class="item-line"></div>
                    </span>
                  </div>
                </a>
                <div
                  v-if="isAuthorized"
                  class="dropdown-item"
                  @click="openView('settings')"
                >
                  <div class="item-link">
                    <span class="line-section">
                      <span>{{ $t("settings") }}</span>
                      <div class="item-line"></div>
                    </span>
                  </div>
                </div>
                <div
                  v-if="showSpartanPlusBenefitsLink"
                  class="dropdown-item"
                  @click="$router.push({ name: 'SpartanPlusPage' })"
                >
                  <div class="item-link">
                    <span class="line-section">
                      <span>{{ $t("spartan_plus_perks") }}</span>
                      <div class="item-line"></div>
                    </span>
                  </div>
                </div>
                <div
                  v-if="isAuthorized"
                  class="dropdown-item"
                  @click="userSignOut"
                >
                  <div class="item-link">
                    <span class="line-section">
                      <span>{{ $t("sign_out") }}</span>
                      <div class="item-line"></div>
                    </span>
                  </div>
                </div>
                <div
                  v-if="!isAuthorized"
                  class="dropdown-item"
                  @click="
                    $router.push({ name: 'formSignUp', query: $route.query })
                  "
                >
                  <div class="item-link">
                    <span class="line-section">
                      <span>{{ $t("join") }}</span>
                      <div class="item-line"></div>
                    </span>
                  </div>
                </div>
                <div
                  v-if="!isAuthorized"
                  class="dropdown-item"
                  @click="
                    $router.push({ name: 'formLogin', query: $route.query })
                  "
                >
                  <div class="item-link">
                    <span class="line-section">
                      <span>{{ $t("sign_in") }}</span>
                      <div class="item-line"></div>
                    </span>
                  </div>
                </div>
                <a
                  v-if="showSpartanPlusLink"
                  class="dropdown-item"
                  :href="headerConfig.spartanPlusLink"
                >
                  <div class="item-link">
                    <span class="line-section">
                      <span>Spartan+</span>
                      <div class="item-line"></div>
                    </span>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div class="country-btn cursor-pointer" @click="openRegionsOverlay">
            <img
              height="16px"
              width="16px"
              src="./../assets/locale_icon.svg"
              alt="flag"
            />
          </div>
        </div>
      </div>
    </div>
    <transition name="slidedown">
      <notification v-if="toolbarNotification.isVisible"></notification>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { UrlPath } from "@/common/helpers";
// import { domains } from "../lists";
import appLinksParams from "@/common/mixins/appLinksParams";
import localizeResolver from "@/common/mixins/localizeResolver";
import HeaderMenu from "@/components/menu/HeaderMenu";
import UserAvatar from "@/components/UserAvatar";

export default {
  name: "Toolbar",
  components: {
    UserAvatar,
    HeaderMenu,
    notification: () => import("./TheToolbarNotification.vue"),
    "tickets-cart-dropdown": () =>
      import("./TicketsCard/TicketsCartDropdown.vue"),
  },
  mixins: [localizeResolver, appLinksParams],
  data: () => ({
    // domainsList: domains,
  }),
  props: ["showSidebar"],
  // react on all getter changes
  computed: {
    ...mapGetters([
      "isAuthorized",
      "specificLinks",
      "userSubscription",
      "toolbarNotification",
    ]),
    showHomeLink() {
      return this.isAuthorized && this.userCountry === "US";
    },
    showSpartanPlusBenefitsLink() {
      return (
        ["US"].includes(this.userCountry) &&
        this.userSubscription &&
        this.userSubscription.isActive
      );
    },
    showSearchLink() {
      return ["US"].includes(this.userCountry);
    },
    headerConfig() {
      let actionUrl = this.localizeConfig?.findRaceUrl;
      if (UrlPath.isRelative(this.localizeConfig?.findRaceUrl)) {
        actionUrl = UrlPath.join(
          this.localizeConfig?.findRaceUrl,
          this.localizeConfig?.homeUrl
        );
      }
      let searchLink = this.specificLinks.search;
      let shopLink = this.specificLinks.shop;
      let ordersLink = this.specificLinks.orders;
      let spartanPlusUrl = "";
      if (this.userCountry === "US" && this.localizeConfig?.homeUrl) {
        spartanPlusUrl =
          "https://www.spartan.com/pages/spartan-plus-membership";
      }
      return {
        homeUrl:
          this.userCountry === "US"
            ? "https://www.spartan.com/"
            : this.localizeConfig?.homeUrl,
        actionButton: actionUrl,
        spartanPlusLink: spartanPlusUrl,
        searchLink,
        shopLink,
        ordersLink,
      };
    },
  },
  methods: {
    ...mapActions(["getHeaderLinks"]),
    openSidebar() {
      this.$emit("handle-sidebar");
    },
    // blurButton() {
    //   setTimeout(() => {
    //     document.activeElement.blur();
    //   }, 200);
    // },
    openRegionsOverlay() {
      this.$emit("handle-regions-overlay");
    },
    openView(name) {
      this.$router.push({ name });
    },
  },
  watch: {
    userLocale: {
      immediate: true,
      handler(val, oldVal) {
        if (
          val !== oldVal &&
          (!this.$route?.meta?.requiredAuth ||
            (this.$route?.meta?.requiredAuth && !this.isAuthorized))
        ) {
          this.getHeaderLinks(this.userLocale);
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
// TODO: need to refactor these styles
.toolbar {
  --toolbar-height: 80px;

  background: #000000;
  position: sticky;
  top: 0;
  z-index: $zindex-toolbar;

  .toolbar-main {
    position: relative;
    height: var(--toolbar-height);
    display: flex;
    background: inherit;
    z-index: $zindex-toolbar;

    .toolbar-sides {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      width: 100%;

      .toolbar-logo {
        margin-left: 70px;
        display: flex;
        position: relative;

        img {
          height: 50px;
          width: 50px;
          border-radius: 50%;
          z-index: 2;
        }
      }

      .toolbar-logo > .logo-circle {
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        border-radius: 50%;
        transform: scale(0);
        transition: all 450ms ease 0ms;
      }

      .toolbar-logo:hover > .logo-circle {
        background: #cf1019;
        border-radius: 50%;
        transform: scale(1);
        transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
      }

      .profile-tools {
        display: flex;
        flex-direction: row;
        align-items: center;

        .toolbar-buttons {
          position: relative;

          .btn-type-1 {
            padding: 0 22px;
            letter-spacing: normal;
          }

          .btn-type-2 {
            display: none;
            letter-spacing: 0.04em;
          }

          .mem-btn {
            display: inline-flex;
            align-items: center;
            font-size: 14px;
            line-height: 14px;
            font-weight: bold;
            text-decoration: none;

            margin-right: 22px;
            min-width: 144px;

            @media screen and (max-width: 1280px) {
              margin-left: 17px;
            }
          }
        }

        .subline-elements {
          display: flex;
          position: relative;

          img {
            margin-right: 18px;
          }

          a {
            display: flex;
            text-decoration: none;
          }

          i {
            font-size: 18px;
            margin-right: 16px;
          }
        }

        .sidebar-elements {
          display: none;

          .open-sidebar-btn {
            margin: 0 17px;

            .menu-line {
              background: #ffffff;
              height: 2px;
              width: 26px;
              transform: rotate(0deg) translate(0px, 0px);
              transition: ease all 0.2s;
            }

            .menu-line:not(:first-child):not(:last-child) {
              margin: 8px 0;
            }
          }

          .open-sidebar-btn:not(.collapsed) .menu-line:nth-child(1) {
            transform: rotate(45deg) translate(6px, 8px);
            transition: ease all 0.2s;
          }

          .open-sidebar-btn:not(.collapsed) .menu-line:nth-child(2) {
            opacity: 0;
            transition: ease all 0.2s;
          }

          .open-sidebar-btn:not(.collapsed) .menu-line:nth-child(3) {
            transform: rotate(-45deg) translate(6px, -8px);
            transition: ease all 0.2s;
          }
        }

        .main-menu-item {
          z-index: 3;
          // display: inline-block;
          display: flex;

          .user-avatar,
          .user-has-avatar {
            margin: 0 17px 0 0;
          }

          .dropdown-menu {
            display: none;
            position: absolute;
            top: 50px;
            right: 13px;
            width: 200px;
            z-index: 1;
            padding-top: 30px;

            .menu-content {
              padding: 14px 0 6px 0;
              background: #ffffff;
              border: 1px solid #dbe0ed;
              box-sizing: border-box;
              box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
              border-radius: 5px;

              .dropdown-item {
                display: flex;
                font-weight: bold;
                font-size: 14px;
                line-height: 14px;
                text-transform: uppercase;
                height: 32px;
                color: #000000;
                padding: 0 24px;
                margin-bottom: 8px;
                text-decoration: none;

                .item-link {
                  display: flex;
                  align-items: flex-start;
                  justify-content: center;
                  flex-direction: column;
                  height: 100%;
                  width: 160px;
                  // box-shadow: 0 1px 0 rgba(219, 224, 237, 0.25);
                  .line-section {
                    position: relative;

                    span:before {
                      content: "";
                      position: absolute;
                      height: 2px;
                      bottom: -7px;
                      left: 0;
                      right: 0;
                      background-color: #000000;
                      width: 100%;
                      max-width: 0;
                      transition: all 0.05s ease-in-out 0s;
                    }
                  }
                }
              }
            }

            .dropdown-item:hover {
              .item-link {
                .line-section {
                  span:before {
                    max-width: 100%;
                  }
                }
              }
            }
          }
        }

        .main-menu-item:hover {
          .dropdown-menu {
            display: block;
          }
        }

        .country-btn {
          margin-right: 50px;

          display: flex;
          flex-direction: column;
          justify-content: center;
          position: relative;
        }
      }
    }
  }
}

@media screen and (max-width: 1279px) {
  .toolbar {
    --toolbar-height: 65px;

    .toolbar-main {
      height: var(--toolbar-height);

      .toolbar-links {
        display: none;
      }

      .toolbar-sides {
        .toolbar-logo {
          margin-left: 17px;

          img {
            height: 44px;
            width: 44px;
          }
        }

        .profile-tools {
          .toolbar-buttons {
            .btn-type-1 {
              display: none;
            }

            .btn-type-2 {
              display: block;
            }
          }

          .subline-elements {
            display: none;
          }

          .sidebar-elements {
            display: flex;

            a {
              display: flex;
            }
          }

          .main-menu-item {
            display: none;
          }

          .country-btn {
            display: none;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 499px) {
  .toolbar {
    .toolbar-main {
      .toolbar-sides {
        .profile-tools {
          width: 100%;
          justify-content: space-between;

          button {
            margin-left: 17px;
          }
        }
      }
    }
  }
}
</style>
