// This file contain images that need to preload based on routes/components needs.

import eventShareStories from "@/assets/event_share_stories.png";
// import eventShareFeed from "@/assets/event_share_feed.png";

const EVENT_SHARE = {
  eventShareStories,
  // eventShareFeed,
};

export { EVENT_SHARE };
