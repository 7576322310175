<template>
  <img
    class="component-menu-image"
    :src="specificData.image.url"
    :alt="specificData.image.filename"
  >
</template>

<script>
import MenuItemResolverMixin from "@/components/menu/provision/MenuItemResolverMixin";
import { get } from 'lodash'

export default {
  name: "componentMenuImage",
  mixins: [ MenuItemResolverMixin ],
  computed: {
    specificData() {
      const linkType = this.itemData.data?.fields?.image?.sys?.linkType;
      const id = this.itemData.data?.fields?.image?.sys?.id;
      const assetData = get(this.references, `${linkType}.${id}`)
      return {
        asset: {
          id,
          linkType
        },
        assetData,
        image: {
          contentType: get(assetData, 'fields.file.contentType'),
          url: get(assetData, 'fields.file.url'),
          details: get(assetData, 'fields.file.details'),
          filename: get(assetData, 'fields.file.fileName'),
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.component-link-promo {

}
</style>
