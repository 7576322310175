<template>
  <a class="component-mobile-app-link" :href="specificData.href" target="_blank">
    <img
      class="component-mobile-app-link-image"
      :src="specificData.image.url"
      :alt="specificData.image.filename"
    >
  </a>
</template>

<script>
import MenuItemResolverMixin from "@/components/menu/provision/MenuItemResolverMixin";
import {get} from "lodash";

export default {
  name: "componentMobileAppLink",
  mixins: [MenuItemResolverMixin],
  computed: {
    specificData() {
      const entryLinkType = this.itemData.data?.fields?.image?.sys?.linkType;
      const entryId = this.itemData.data?.fields?.image?.sys?.id;
      const entryData = get(this.references, `${entryLinkType}.${entryId}`)
      const assetLinkType = entryData?.fields?.image?.sys?.linkType;
      const assetId = entryData?.fields?.image?.sys?.id;
      const assetData = get(this.references, `${assetLinkType}.${assetId}`)
      return {
        entry: {
          entryId,
          entryLinkType,
          entryData
        },
        asset: {
          assetId,
          assetLinkType,
          assetData
        },
        image: {
          contentType: get(assetData, 'fields.file.contentType'),
          url: get(assetData, 'fields.file.url'),
          details: get(assetData, 'fields.file.details'),
          filename: get(assetData, 'fields.file.fileName'),
          alt: get(entryData, 'fields.title')
        },
        href: this.itemData?.data?.fields?.url
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.component-mobile-app-link {
  display: inline-block;

  .component-mobile-app-link-image {
    width: 136px;

    @media screen and (max-width: 1280px) {
      width: 111px;
    }
  }

  & + .component-mobile-app-link {
    margin-top: 19px;
  }
}
</style>
