<template>
  <nav class="sidebar-menu" role="navigation">
    <div
      class="menu-link"
      v-for="(item, index) in menuItems"
      :key="index"
      :class="{'is-opened': selectedNodes.some(node => node === item.id)}"
    >
      <a
        class="tree-line cursor-pointer"
        @click="toggleNode(item.id)"
      >
        <span>{{ item.text }}</span>
        <i class="material-icons">
          {{ selectedNodes.some(node => node === item.id) ? 'remove' : 'add' }}
        </i>
      </a>
      <div class="link-content" v-if="item.children">
        <div class="container">
          <menu-item
            v-for="(link, i) in item.children"
            :key="`0-${index}-${i}-mobile`"
            :parent-index="`0-${index}-${i}-mobile`"
            :item="link"
            :references="headerLinks.references"
            is-mobile
          />
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import {mapGetters} from "vuex";
import {get} from 'lodash'
import MenuItem from "@/components/menu/MenuItem";

export default {
  name: "SidebarMenu",
  components: {MenuItem},
  data() {
    return {
      selectedNodes: []
    }
  },
  computed: {
    ...mapGetters([
      "headerLinks",
    ]),
    menuItems() {
      let res = [];
      if (this.headerLinks?.menuItems) {
        res = this.headerLinks.menuItems.map(item => ({
          origin: {...item},
          id: item?.sys?.id,
          data: this.headerLinks.references[item.sys.linkType][item.sys.id],
          text: get(this.headerLinks.references[item.sys.linkType][item.sys.id], 'fields.text'),
          type: get(this.headerLinks.references[item.sys.linkType][item.sys.id], 'sys.contentType.sys.id'),
          children: get(this.headerLinks.references[item.sys.linkType][item.sys.id], 'fields.components'),
        }));
      }
      return res;
    }
  },
  methods: {
    toggleNode(id) {
      const index = this.selectedNodes.indexOf(id);
      if (index >= 0) {
        this.selectedNodes.splice(index, 1)
      } else {
        this.selectedNodes.push(id)
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.sidebar-menu {
  display: flex;
  flex-direction: column;

  .menu-link {
    border-bottom: 1px solid hsla(0, 0%, 100%, .2);
    font-size: 14px;
    line-height: 17px;
    font-weight: 700;
    letter-spacing: .04em;
    overflow: hidden;
    transition: all .7s ease-in-out;

    .tree-line {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      line-height: 50px;
      padding: 5px 20px;
      position: relative;
      cursor: pointer;
    }

    .link-content {
      // display: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      padding-left: 20px;
      padding-right: 15px;
      padding-bottom: 15px;

      .container {
        flex-direction: column;
        flex-wrap: nowrap;
      }

      &::v-deep {
        & .container > .component-link-small {
          margin: 0;
          height: fit-content;

          & + .component-link-small {
            margin-left: 30px;
          }
        }
      }
    }

    .container {
      display: flex;
      flex-wrap: wrap;
      padding-top: 3rem;
      padding-bottom: 1.5rem;
      max-width: 1140px;

      @media screen and (max-width: 1280px){
        flex-wrap: nowrap;
        flex-direction: column;
      }
    }

    &:hover {
      .link-name {
        span:before {
          max-width: 100%;
        }
      }

      .link-content {
        opacity: 1;
        visibility: visible;
        transition: opacity 0.1s, visibility 0ms;
        transition-delay: 0.3s;
      }
    }

    &:not(.is-opened) {
      .link-content {
        display: none;
      }
    }
  }
}
</style>
