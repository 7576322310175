<template>
  <div class="component-menu-sidebar">
    <slot />
  </div>
</template>

<script>
import MenuItemResolverMixin from "@/components/menu/provision/MenuItemResolverMixin";

export default {
  name: "componentMenuSidebar",
  mixins: [ MenuItemResolverMixin ],
}
</script>

<style lang="scss" scoped>
.component-menu-sidebar {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  flex: 1 0 100%;
  column-gap: 100px;
  // row-gap: 40px;

  @media screen and (max-width: 1280px) {
    grid-template-columns: 1fr;
    padding-left: 20px;
  }
}
</style>
