export const TERM_BLACKLIST = [
  "50 yard cunt punt",
  "a_s_s",
  "a2m",
  "a$$hole",
  "a55hole",
  "aeolus",
  "ahole",
  "anal",
  "anal impaler",
  "anal leakage",
  "analprobe",
  "anilingus",
  "anus",
  "ar5e",
  "areola",
  "areole",
  "arian",
  "aryan",
  "ass",
  "ass fuck",
  "ass hole",
  "ass-fucker",
  "assbang",
  "assbanged",
  "assbangs",
  "asses",
  "assfuck",
  "assfucker",
  "assfukka",
  "assh0le",
  "asshat",
  "assho1e",
  "asshole",
  "assholes",
  "assmaster",
  "assmucus",
  "assmunch",
  "asswhole",
  "asswipe",
  "asswipes",
  "autoerotic",
  "azazel",
  "azz",
  "b!tch",
  "b00bs",
  "b17ch",
  "b1tch",
  "ballbag",
  "ballsack",
  "bang",
  "bang (one's) box",
  "bangbros",
  "banger",
  "bareback",
  "bastard",
  "bastards",
  "bawdy",
  "beaner",
  "beardedclam",
  "beastial",
  "beastiality",
  "beatch",
  "beef curtain",
  "beeyotch",
  "bellend",
  "beotch",
  "bestial",
  "bestiality",
  "bi+ch",
  "biatch",
  "big tits",
  "bigtits",
  "birdlock",
  "bitch",
  "bitch tit",
  "bitched",
  "bitcher",
  "bitchers",
  "bitches",
  "bitchin",
  "bitching",
  "bitchy",
  "blow job",
  "blow me",
  "blow mud",
  "blowjob",
  "blowjobs",
  "blue waffle",
  "blumpkin",
  "boink",
  "boiolas",
  "bollock",
  "bollocks",
  "bollok",
  "boned",
  "boner",
  "boners",
  "bong",
  "boob",
  "boobies",
  "boobs",
  "booby",
  "booger",
  "bookie",
  "booobs",
  "boooobs",
  "booooobs",
  "booooooobs",
  "bootee",
  "bootie",
  "bosom",
  "bosomy",
  "bowel",
  "bowels",
  "brassiere",
  "breast",
  "breasts",
  "buceta",
  "bugger",
  "bukkake",
  "bull shit",
  "bullshit",
  "bullshits",
  "bullshitted",
  "bullturds",
  "bung",
  "bunny fucker",
  "bust a load",
  "busty",
  "butt fuck",
  "butt fuck",
  "buttfuck",
  "buttfucker",
  "butthole",
  "buttmuch",
  "buttplug",
  "c-0-c-k",
  "c-o-c-k",
  "c-u-n-t",
  "c.0.c.k",
  "c.o.c.k.",
  "c.u.n.t",
  "c0ck",
  "c0cksucker",
  "caca",
  "cahone",
  "cameltoe",
  "carpet muncher",
  "carpetmuncher",
  "cawk",
  "cervix",
  "chinc",
  "chincs",
  "chink",
  "choade",
  "chode",
  "chodes",
  "chota bags",
  "cipa",
  "cl1t",
  "climax",
  "clit",
  "clit licker",
  "clitoris",
  "clitorus",
  "clits",
  "clitty",
  "clitty litter",
  "clusterfuck",
  "cnut",
  "cocain",
  "cock",
  "cock pocket",
  "cock snot",
  "cock sucker",
  "cock-sucker",
  "cockblock",
  "cockface",
  "cockhead",
  "cockholster",
  "cockknocker",
  "cockmunch",
  "cockmuncher",
  "cocks",
  "cocksmoker",
  "cocksuck",
  "cocksucked",
  "cocksucker",
  "cocksucking",
  "cocksucks",
  "cocksuka",
  "cocksukka",
  "coital",
  "cok",
  "cokmuncher",
  "coksucka",
  "commie",
  "condom",
  "coon",
  "coons",
  "cop some wood",
  "corksucker",
  "cornhole",
  "corp whore",
  "cracker",
  "crackwhore",
  "cum",
  "cum chugger",
  "cum dumpster",
  "cum freak",
  "cum guzzler",
  "cumdump",
  "cummer",
  "cummin",
  "cumming",
  "cums",
  "cumshot",
  "cumshots",
  "cumslut",
  "cumstain",
  "cunilingus",
  "cunillingus",
  "cunnilingus",
  "cunny",
  "cunt",
  "cunt hair",
  "cunt-struck",
  "cuntbag",
  "cuntface",
  "cunthunter",
  "cuntlick",
  "cuntlick",
  "cuntlicker",
  "cuntlicker",
  "cuntlicking",
  "cunts",
  "cuntsicle",
  "cut rope",
  "cyalis",
  "cyberfuc",
  "cyberfuck",
  "cyberfucked",
  "cyberfucker",
  "cyberfuckers",
  "cyberfucking",
  "d0ng",
  "d0uch3",
  "d0uche",
  "d1ck",
  "d1ld0",
  "d1ldo",
  "dago",
  "dagos",
  "dammit",
  "damnit",
  "dawgie-style",
  "dick hole",
  "dick shy",
  "dick-ish",
  "dickbag",
  "dickdipper",
  "dickface",
  "dickflipper",
  "dickhead",
  "dickheads",
  "dickish",
  "dickripper",
  "dicksipper",
  "dickweed",
  "dickwhipper",
  "dickzipper",
  "diddle",
  "dike",
  "dildo",
  "dildos",
  "diligaf",
  "dillweed",
  "dimwit",
  "dingle",
  "dink",
  "dinks",
  "dipship",
  "dirsa",
  "dirty Sanchez",
  "dlck",
  "dog-fucker",
  "dong",
  "donkeyribber",
  "doofus",
  "doosh",
  "douch3",
  "douche",
  "douchebag",
  "douchebags",
  "douchey",
  "duche",
  "dumass",
  "dyke",
  "dykes",
  "eat a dick",
  "eat hair pie",
  "ejaculate",
  "ejaculated",
  "ejaculates",
  "ejaculating",
  "ejaculatings",
  "ejaculation",
  "ejakulate",
  "enlargement",
  "erect",
  "erection",
  "erotic",
  "essohbee",
  "f u c k",
  "f u c k e r",
  "f_u_c_k",
  "f-u-c-k",
  "f.u.c.k",
  "f4nny",
  "facial",
  "fack",
  "fag",
  "fagg",
  "fagged",
  "fagging",
  "faggit",
  "faggitt",
  "faggot",
  "faggs",
  "fagot",
  "fagots",
  "fags",
  "faig",
  "faigt",
  "fanny",
  "fannybandit",
  "fannyflaps",
  "fannyfucker",
  "fanyy",
  "fartknocker",
  "fatass",
  "fcuk",
  "fcuker",
  "fcuking",
  "feck",
  "fecker",
  "felch",
  "felcher",
  "felching",
  "fellate",
  "fellatio",
  "feltch",
  "feltcher",
  "fingerfuck",
  "fingerfucked",
  "fingerfucker",
  "fingerfuckers",
  "fingerfucking",
  "fingerfucks",
  "fist fuck",
  "fisted",
  "fistfuck",
  "fistfucked",
  "fistfucker",
  "fistfuckers",
  "fistfucking",
  "fistfuckings",
  "fistfucks",
  "fisting",
  "fisty",
  "flog the log",
  "floozy",
  "foad",
  "fondle",
  "foobar",
  "fook",
  "fooker",
  "foreskin",
  "freex",
  "frigg",
  "frigga",
  "fubar",
  "fuck",
  "fuck",
  "fuck hole",
  "fuck puppet",
  "fuck trophy",
  "fuck yo mama",
  "fuck-ass",
  "fuck-bitch",
  "fuck-tard",
  "fucka",
  "fuckass",
  "fucked",
  "fucker",
  "fuckers",
  "fuckface",
  "fuckhead",
  "fuckheads",
  "fuckin",
  "fucking",
  "fuckings",
  "fuckingshitmotherfucker",
  "fuckme",
  "fuckmeat",
  "fucknugget",
  "fucknut",
  "fuckoff",
  "fucks",
  "fucktard",
  "fucktoy",
  "fuckup",
  "fuckwad",
  "fuckwhit",
  "fuckwit",
  "fudge packer",
  "fudgepacker",
  "fuk",
  "fuker",
  "fukker",
  "fukkin",
  "fuks",
  "fukwhit",
  "fukwit",
  "fux",
  "fux0r",
  "fvck",
  "fxck",
  "g-spot",
  "gae",
  "gai",
  "gang-bang",
  "gangbang",
  "gangbang",
  "gangbanged",
  "gangbangs",
  "gassy ass",
  "gaylord",
  "gaysex",
  "gey",
  "gfy",
  "ghay",
  "ghey",
  "gigolo",
  "glans",
  "goatse",
  "god damn",
  "god-dam",
  "god-damned",
  "godamn",
  "godamnit",
  "goddam",
  "goddammit",
  "goddamn",
  "goddamned",
  "goldenshower",
  "gonad",
  "gonads",
  "gook",
  "gooks",
  "gringo",
  "gspot",
  "gtfo",
  "guido",
  "h0m0",
  "h0mo",
  "ham flap",
  "handjob",
  "hard on",
  "hardcoresex",
  "he11",
  "hebe",
  "heeb",
  "herpy",
  "heshe",
  "hitler",
  "hiv",
  "hoar",
  "hoare",
  "hobag",
  "hoer",
  "hom0",
  "homey",
  "homo",
  "homoerotic",
  "homoey",
  "honky",
  "hooker",
  "hoor",
  "hootch",
  "hooter",
  "hore",
  "horniest",
  "horny",
  "hotsex",
  "how to murdep",
  "humped",
  "humping",
  "hussy",
  "hymen",
  "incest",
  "injun",
  "j3rk0ff",
  "jack-off",
  "jackass",
  "jackhole",
  "jackoff",
  "jap",
  "japs",
  "jerk-off",
  "jerk0ff",
  "jerked",
  "jerkoff",
  "jism",
  "jiz",
  "jizm",
  "jizz",
  "jizzed",
  "junkie",
  "junky",
  "kawk",
  "kike",
  "kikes",
  "kinky",
  "kinky Jesus",
  "kkk",
  "klan",
  "knob end",
  "knobead",
  "knobed",
  "knobend",
  "knobhead",
  "knobjocky",
  "knobjokey",
  "kock",
  "kondum",
  "kondums",
  "kooch",
  "kooches",
  "kootch",
  "kraut",
  "kum",
  "kummer",
  "kumming",
  "kums",
  "kunilingus",
  "kwif",
  "kyke",
  "l3i+ch",
  "l3itch",
  "labia",
  "lech",
  "LEN",
  "leper",
  "lesbo",
  "lesbos",
  "lez",
  "lezbian",
  "lezbians",
  "lezbo",
  "lezbos",
  "lezzie",
  "lezzies",
  "lezzy",
  "loin",
  "loins",
  "lube",
  "lust",
  "lusting",
  "lusty",
  "m-fucking",
  "m0f0",
  "m0fo",
  "m45terbate",
  "ma5terb8",
  "ma5terbate",
  "mafugly",
  "mams",
  "masochist",
  "massa",
  "master-bate",
  "masterb8",
  "masterbat*",
  "masterbat3",
  "masterbate",
  "masterbating",
  "masterbation",
  "masterbations",
  "masturbate",
  "masturbating",
  "masturbation",
  "maxi",
  "menses",
  "menstruate",
  "menstruation",
  "mo-fo",
  "mof0",
  "mofo",
  "molest",
  "moolie",
  "mothafuck",
  "mothafucka",
  "mothafuckas",
  "mothafuckaz",
  "mothafucked",
  "mothafucker",
  "mothafuckers",
  "mothafuckin",
  "mothafucking",
  "mothafuckings",
  "mothafucks",
  "mother fucker",
  "mother fucker",
  "motherfuck",
  "motherfucka",
  "motherfucked",
  "motherfucker",
  "motherfuckers",
  "motherfuckin",
  "motherfucking",
  "motherfuckings",
  "motherfuckka",
  "motherfucks",
  "mtherfucker",
  "mthrfucker",
  "mthrfucking",
  "muff",
  "muff puff",
  "muffdiver",
  "mutha",
  "muthafecker",
  "muthafuckaz",
  "muthafucker",
  "muthafuckker",
  "muther",
  "mutherfucker",
  "mutherfucking",
  "muthrfucking",
  "n1gga",
  "n1gger",
  "nad",
  "nads",
  "napalm",
  "nappy",
  "nazi",
  "nazism",
  "need the dick",
  "negro",
  "nigg3r",
  "nigg4h",
  "nigga",
  "niggah",
  "niggas",
  "niggaz",
  "nigger",
  "niggers",
  "niggle",
  "niglet",
  "nimrod",
  "ninny",
  "nob",
  "nob jokey",
  "nobhead",
  "nobjocky",
  "nobjokey",
  "nooky",
  "numbnuts",
  "nut butter",
  "nutsack",
  "nympho",
  "oral",
  "orally",
  "orgasim",
  "orgasims",
  "orgasm",
  "orgasmic",
  "orgasms",
  "orgies",
  "orgy",
  "ovary",
  "ovum",
  "ovums",
  "p.u.s.s.y.",
  "p0rn",
  "paddy",
  "paki",
  "pantie",
  "panties",
  "panty",
  "pastie",
  "pasty",
  "pawn",
  "pcp",
  "pecker",
  "pedo",
  "pedophile",
  "pedophilia",
  "pedophiliac",
  "pee",
  "peepee",
  "penetrate",
  "penetration",
  "penial",
  "penile",
  "penis",
  "penisfucker",
  "perversion",
  "phalli",
  "phallic",
  "phonesex",
  "phuck",
  "phuk",
  "phuked",
  "phuking",
  "phukked",
  "phukking",
  "phuks",
  "phuq",
  "pigfucker",
  "pillowbiter",
  "pimp",
  "pimpis",
  "pinko",
  "piss",
  "piss-off",
  "pissed",
  "pisser",
  "pissers",
  "pisses",
  "pissflaps",
  "pissin",
  "pissing",
  "pissoff",
  "pissoff",
  "pms",
  "polack",
  "pollock",
  "poon",
  "poontang",
  "porn",
  "porno",
  "pornography",
  "pornos",
  "prig",
  "pron",
  "prostitute",
  "prude",
  "pube",
  "pubic",
  "pubis",
  "punkass",
  "punky",
  "puss",
  "pusse",
  "pussi",
  "pussies",
  "pussy",
  "pussy fart",
  "pussy palace",
  "pussypounder",
  "pussys",
  "puto",
  "queaf",
  "queaf",
  "queef",
  "queero",
  "quicky",
  "quim",
  "r-tard",
  "rape",
  "raped",
  "raper",
  "rapist",
  "raunch",
  "rectal",
  "rectum",
  "rectus",
  "reetard",
  "reich",
  "retard",
  "retarded",
  "revue",
  "rimjaw",
  "rimjob",
  "rimming",
  "ritard",
  "rtard",
  "rump",
  "rumprammer",
  "s hit",
  "s_h_i_t",
  "s-h-1-t",
  "s-h-i-t",
  "s-o-b",
  "s.h.i.t.",
  "s.o.b.",
  "s0b",
  "sadism",
  "sadist",
  "sandbar",
  "sausage queen",
  "scag",
  "scantily",
  "schizo",
  "schlong",
  "scroat",
  "scrog",
  "scrot",
  "scrote",
  "scrotum",
  "scrud",
  "scum",
  "seaman",
  "seamen",
  "seduce",
  "semen",
  "sex",
  "sexual",
  "sh!+",
  "sh!t",
  "sh1t",
  "shag",
  "shagger",
  "shaggin",
  "shagging",
  "shamedame",
  "shemale",
  "shi+",
  "shit",
  "shit fucker",
  "shitdick",
  "shite",
  "shiteater",
  "shited",
  "shitey",
  "shitface",
  "shitfuck",
  "shitfull",
  "shithead",
  "shithole",
  "shithouse",
  "shiting",
  "shitings",
  "shits",
  "shitt",
  "shitted",
  "shitter",
  "shitters",
  "shitting",
  "shittings",
  "shitty",
  "shiz",
  "sissy",
  "skag",
  "skank",
  "sleaze",
  "sleazy",
  "slut",
  "slut bucket",
  "slutdumper",
  "slutkiss",
  "sluts",
  "smegma",
  "smut",
  "smutty",
  "snatch",
  "snuff",
  "sodom",
  "son-of-a-bitch",
  "souse",
  "soused",
  "spac",
  "sperm",
  "spic",
  "spick",
  "spik",
  "spiks",
  "spooge",
  "steamy",
  "stfu",
  "stiffy",
  "sumofabiatch",
  "t1t",
  "t1tt1e5",
  "t1tties",
  "tampon",
  "tard",
  "tawdry",
  "teabagging",
  "teat",
  "teets",
  "terd",
  "teste",
  "testee",
  "testes",
  "testical",
  "testicle",
  "testis",
  "thug",
  "tinkle",
  "tit",
  "tit wank",
  "titfuck",
  "titi",
  "tits",
  "titt",
  "tittie5",
  "tittiefucker",
  "titties",
  "titty",
  "tittyfuck",
  "tittyfucker",
  "tittywank",
  "titwank",
  "toke",
  "toots",
  "tosser",
  "tramp",
  "tubgirl",
  "tush",
  "tw4t",
  "twat",
  "twathead",
  "twats",
  "twatty",
  "twunt",
  "twunter",
  "unwed",
  "urinal",
  "urine",
  "uterus",
  "uzi",
  "v14gra",
  "v1gra",
  "vag",
  "vagina",
  "valium",
  "viagra",
  "virgin",
  "vixen",
  "voyeur",
  "vulva",
  "w00se",
  "wang",
  "wazoo",
  "wedgie",
  "weenie",
  "weewee",
  "weiner",
  "wench",
  "wetback",
  "wh0re",
  "wh0reface",
  "whitey",
  "whiz",
  "whoar",
  "whoralicious",
  "whore",
  "whorealicious",
  "whored",
  "whoreface",
  "whorehopper",
  "whorehouse",
  "whores",
  "whoring",
  "wigger",
  "willies",
  "willy",
  "womb",
  "wop",
  "xxx",
  "yeasty",
  "yobbo",
  "zoophile"
];

export default {
  TERM_BLACKLIST
};
