<template>
  <div class="component-menu-tree-node">
      <div
        class="menu-link"
        v-for="(item, index) in preparedData"
        :key="index"
        :class="{'is-opened': selectedNodes.some(node => node === item.id)}"
      >
        <a
          v-if="item.children"
          class="tree-line cursor-pointer"
          @click="toggleNode(item.id)"
        >
          <span>{{ item.text }}</span>

          <i class="material-icons">
            {{ selectedNodes.some(node => node === item.id) ? 'remove' : 'add' }}
          </i>
        </a>
        <component
          v-else
          :is="item.component"
          class="tree-line cursor-pointer"
          :item="item.origin.origin"
          :references="references"
        />
        <div class="link-content" v-if="selectedNodes.some(node => node === item.id)">
            <component
              v-for="(link, i) in item.children"
              :key="`1-${index}-${i}-mobile`"
              :is="link.component"
              :parent-index="`1-${index}-${i}-mobile`"
              :item="link"
              :references="references"
              is-mobile
            />
        </div>
      </div>
  </div>
</template>

<script>
import MenuItemResolverMixin from "@/components/menu/provision/MenuItemResolverMixin";
import {get} from "lodash";

export default {
  name: "componentMenuTreeNode",
  mixins: [ MenuItemResolverMixin ],
  data() {
    return {
      selectedNodes: []
    }
  },
  computed: {
    treeData() {
      return this.itemData.children
        .map(item => ({
          ...this.references.Entry[item?.sys?.id],
          origin: item
        }))
        .reduce((acc, current) => {
        const type = get(current, 'sys.contentType.sys.id')
          const prev = acc[acc.length - 1];
          if (acc.length > 0 && prev?.sys?.contentType?.sys?.id === 'componentLinkBig' && type !== 'componentLinkBig') {
            if (!prev.fields.components) prev.fields.components = []
            if (!prev.fields.components.find(item => item.sys?.id === current.sys?.id)) {
              prev.fields.components.push({
                ...current.origin,
                component: () => import('@/components/menu/MenuItem.vue'),
              })
            }
          } else {
            acc.push(current);
          }
          return acc
      }, []);
    },
    preparedData() {
      return this.treeData.map(item => {
        const id = item?.sys?.id;
        return {
          id,
          origin: {...item},
          text: get(item, 'fields.text'),
          type: get(item, 'sys.contentType.sys.id'),
          children: get(item, 'fields.components'),
          component: () => import('@/components/menu/MenuItem.vue'),
        }
      })
    }
  },
  methods: {
    toggleNode(id) {
      const index = this.selectedNodes.indexOf(id);
      if (index >= 0) {
        this.selectedNodes.splice(index, 1)
      } else {
        this.selectedNodes.push(id)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.component-menu-tree-node {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  max-width: 100%;
  padding: 0 15px;

  &:first-child {
    padding-left: 0;
  }

  @media screen and (max-width: 1280px) {
    padding: 0;
  }

  &::v-deep {
    & + .component-link-small {
      display: inline-block;
      flex: 1 0;
      height: fit-content;
    }
    & + .component-menu-sidebar {
      flex-basis: 575px;
      padding: 35px 0 40px 110px;
      border-left: 1px solid #2a2929;
      box-sizing: border-box;


      @media screen and (max-width: 1280px) {
        grid-template-columns: 1fr;
        padding: 0 40px 0 20px;
        border-left: none;
        margin-top: 36px;
      }
    }
  }

  .menu-link {
      font-size: 14px;
      line-height: 17px;
      font-weight: 700;
      letter-spacing: .04em;
      overflow: hidden;
      transition: all .7s ease-in-out;

      .tree-line {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        line-height: 40px;
        padding: 0 5px 0 20px;
        font-weight: 500;
        font-size: 16px;
        position: relative;
        cursor: pointer;
        box-sizing: border-box;

        &.component-mobile-app-link {
          padding-top: 17px;
        }
      }

      .link-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-wrap: nowrap;
        position: relative;
        padding-left: 63px;

        &::v-deep {
          .component-link-small {
            line-height: 40px;
            font-size: 16px;
            font-weight: 500;

            & + .component-link-big,
            & + .component-link-small
            {
              margin-top: 0;
            }
            & + .component-mobile-app-link {
              margin-top: 36px;
            }
          }
        }
      }
    }
}
</style>
