import { getCookie } from "@/cookie";
import config from "@/config";

const API = config.apiAccount;

export default {
  async loadOutfits(ctx) {
    const authorization = getCookie("user");
    const response = await fetch(`${API}/shopify/products`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authorization,
      }),
    });
    if (!response.ok) {
      return Promise.reject({
        status: response.status,
        statusText: response.statusText,
      });
    }
    const responseData = await response.json();
    ctx.commit("setOutfitsData", responseData?.products || []);
    return Promise.resolve(responseData);
  },
};
