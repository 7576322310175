<template>
  <v-app
    :class="{
      'auth-wrap': authWrap,
      'full-height-overlay': !showHeader,
      // move ZD widget up
      'has-tickets': showTicketsCart || $route.name === 'checkout',
      ...localeClass,
    }"
  >
    <!-- toolbar -->
    <Toolbar
      :showSidebar="sidebarIsOpen"
      v-on:handle-sidebar="handleSidebar"
      v-on:handle-regions-overlay="handleRegionsOverlay"
      v-if="showHeader"
    />
    <!-- tickets cart bottom button -->
    <tickets-cart-button v-if="showTicketsCart" />

    <!-- sidebar -->
    <div class="mem-sidebar-overlay" id="overlay"></div>
    <transition name="fade-transition" mode="out-in">
      <div
        v-if="sidebarIsOpen"
        class="sidebar-overlay"
        @click="handleSidebar()"
      ></div>
    </transition>
    <transition name="slide">
      <Sidebar
        v-if="sidebarIsOpen"
        v-on:handle-sidebar="handleSidebar"
        v-on:handle-regions-overlay="handleRegionsOverlay"
      />
    </transition>

    <!-- overlays -->
    <!-- region select -->
    <v-overlay
      :value="showCountryOverlay"
      opacity="1"
      color="#FFFFFF"
      z-index="1000002"
    >
      <region-switcher v-on:handle-regions-overlay="handleRegionsOverlay" />
    </v-overlay>

    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { isEqual, trim } from "lodash";
import { pushDataLayerEvent } from "@/common/helpers";
import Zendesk from "@/services/zendesk";
import localizeResolver from "@/common/mixins/localizeResolver";

import Toolbar from "@/components/TheToolbar.vue";
import Sidebar from "@/components/TheSidebar.vue";
import TicketsCartButton from "@/components/TicketsCard/TicketsCartButton.vue";
import RegionSwitcher from "@/components/RegionsSwitcher/RegionsList.vue";

export default {
  name: "app",
  mixins: [localizeResolver],
  inject: {
    Zendesk,
  },
  data() {
    return {
      sidebarIsOpen: false,
      forms: [
        "formLogin",
        "formSignIn",
        "formSignUp",
        "formRequestPasswordReset",
        "formResetPassword",
        "resetEmailSend",
        "resetPasswordSuccess",
        "resetPasswordLinkExpired",
        "confirmationExpired",
      ],
    };
  },
  components: {
    Toolbar,
    Sidebar,
    RegionSwitcher,
    TicketsCartButton,
  },
  computed: {
    ...mapGetters([
      "userData",
      "showCountryOverlay",
      "storedTickets",
      "loadedUserLocale",
      "isLoadedUserData",
      "localeTranslationsLoaded",
      "userLocaleTranslationsLoaded",
    ]),
    showHeader() {
      let list = [
        "subscription",
        "eventTickets",
        "joinTeam",
        "checkout",
        "inviteTeamToEvent",
      ];
      if (!this.$route.name) return false;
      return !list.includes(this.$route.name);
    },
    showTicketsCart() {
      if (!this.$route.name) return false;
      const list = [
        ...this.forms,
        "subscription",
        "eventTickets",
        "settings",
        "checkout",
        "tickets",
      ];
      return this.storedTickets.length > 0 && !list.includes(this.$route.name);
    },
    redirectTrigger() {
      return this.userCountry !== "US" && this.$route.path === "/home";
    },
    // App classes
    authWrap() {
      return this.forms.includes(this.$route.name);
    },
    localeClass() {
      if (this.userLang === "el")
        return {
          "lang-el": true,
        };
      return "";
    },
    // GTM data
    pageViewInfo() {
      let user = {};

      user.countryCode = this.userData.spartan_country_code;
      user.language = this.userData.language;
      if (this.isLoadedUserData) {
        user.spartanUserId = this.userData.id;
        user.spartanPlusMember = this.userData.spartan_plus_member || false;
        user.email = this.userData.email;
      }
      return user;
    },
  },
  methods: {
    ...mapActions([
      "getTranslations",
      "handleOverlay",
      "loadAvatar",
      "getCookieTickets",
      "getCart",
      "handleToolbarNotification",
    ]),
    handleSidebar() {
      this.sidebarIsOpen = !this.sidebarIsOpen;
    },
    handleRegionsOverlay() {
      this.handleOverlay("country");
    },
    async checkAppVersion() {
      let { VUE_APP_ENV, VUE_APP_VERSION } = process.env;

      if (VUE_APP_ENV === "local") return true;

      let res = await fetch(`${location.origin}/version`);
      let latest = await res.text();

      console.log(`App created ${latest}`);
      console.log(`Current ${VUE_APP_VERSION}`);

      return isEqual(trim(latest), VUE_APP_VERSION);
    },
  },
  async created() {
    try {
      let isLatestVersion = await this.checkAppVersion();
      console.log("Latest version", isLatestVersion);

      if (!isLatestVersion)
        this.handleToolbarNotification({
          type: "warning",
          text: "Please update your website version to continue using the service.",
          isVisible: false,
          canClose: false,
          body: "appVersion",
        });
    } catch (error) {
      console.log("Can't check app version", error);
    }
    this.$router.onReady(async () => {
      this.loadLocalizeParams();
      await this.getTranslations(this.userData?.language);
      // hide account load ovelay
      if (!this.showHeader) this.loadAvatar();
    });
    await this.getCart();
  },
  watch: {
    $route: {
      handler(to, from) {
        // TODO: Move sidebar display logic into Sidebar component
        if (to?.path !== from?.path && this.sidebarIsOpen) {
          this.sidebarIsOpen = false;
        }

        // GTM Route change events
        let { requiredAuth } = to.meta || {};
        if (requiredAuth && !this.isLoadedUserData)
          return console.log("User not loaded yet");

        pushDataLayerEvent("pageViewExperience", {
          pageViewInfo: this.pageViewInfo,
        });
      },
    },
    isLoadedUserData() {
      pushDataLayerEvent("pageViewExperience", {
        pageViewInfo: this.pageViewInfo,
      });
    },
    redirectTrigger(val) {
      if (val) {
        this.$router.replace("/results");
      }
    },
    loadedUserLocale: {
      immediate: true,
      handler(val, oldVal) {
        if (val && val !== oldVal) {
          this.Zendesk.init(this.localizeConfig?.ZDServiceKey);
          if (this.isLoadedUserData) {
            this.Zendesk.fillUserData(this.userData);
          }
        }
      },
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/styles/typography";
@font-face {
  font-family: "Druk Wide";
  src: url("~@/assets/fonts/druk/DrukWide-Medium.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Druk Wide";
  src: url("~@/assets/fonts/druk/DrukWide-Heavy.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Druk Cond";
  src: url("~@/assets/fonts/druk/DrukCond-Super.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Druk";
  src: url("~@/assets/fonts/druk/Druk-Medium.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Druk";
  src: url("~@/assets/fonts/druk/Druk-Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "DIN Pro";
  src: url("~@/assets/fonts/din/DIN-Pro-Cond-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "MonoSpec";
  src: url("~@/assets/fonts/MonoSpec/MonoSpec-SemiBold.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
html {
  scroll-behavior: smooth;
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  background: #020000;
}
#app {
  @include memApp;
  .mem-tooltip {
    background: #ffffff;
    opacity: 1;
    color: #000000;
    padding: 0;
    border: 1px solid rgba(34, 34, 34, 0.2);
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.18);
    border-radius: 12px;
    pointer-events: auto;
  }
}
.lang-el {
  @import "~@/assets/styles/typography-el";
  @include memApp;
}
.auth-wrap {
  height: 100%;
  .v-application--wrap {
    min-height: fit-content;
    .container {
      height: 100%;
    }
  }
}
.full-height-overlay {
  .account-overlay {
    margin-top: 0 !important;
  }
}
.mem-view {
  padding-top: 70px;
  padding-bottom: 100px;
  @media screen and (max-width: 499px) {
    padding-top: 50px;
  }
}
#sezzle-smart-button-container {
  .sezzle-smart-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    // background-color: #cf1019 !important;
    // transition: none !important;
    padding: 0 !important;
    margin: 0 !important;
    border: none !important;
    border-radius: 22.7053px !important;
    // &:hover {
    //   opacity: 0.7;
    // }
    .template-text {
      font-family: "Montserrat", "Roboto", sans-serif;
      font-size: 14px !important;
      font-weight: bold;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      margin: 0 !important;
    }
  }
}
.list-menu {
  background-color: #121212;
  .list-menu-item {
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    padding: 5px 0 24px 26px;
  }
}

input[type="password"] {
  font-size: 18px;
  letter-spacing: 0.185em;
}
input[type="date"] {
  -webkit-appearance: none;
}
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-clear-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.sidebar-overlay {
  position: fixed;

  top: 65px;
  right: 0;

  height: 100%;
  width: 100%;
  z-index: 3;

  background: rgba(0, 0, 0, 0.2);
}
.mem-sidebar-overlay {
  background-color: black;
}
#overlay {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(207, 16, 25, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}
.cursor-pointer {
  cursor: pointer;
  outline: none;
}
.mem-show-mobile {
  display: none;
}
.mem-show-desktop {
  display: block;
}
// .mem-cropper-image {
// }
// .mem-cropper-boundaries {
// }
// .mem-cropper-background {
// }
// .mem-cropper-foreground {
// }
.mem-cropper-preview {
  // border-radius: 4px;
}
.mem-bounding-box {
  // background: #ffffff;
}
// !! You need to add this class to all vuetify dialogs. (Show dialog components over zendesk widget) !!
// TODO: add this class to all vuetify dialogs
.mem-dialog {
  z-index: 999999;
}

// slide
.slide-leave-active,
.slide-enter-active {
  transition: 0.3s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(100%, 0);
}
//

// toolbar notification
.slidedown-enter-active,
.slidedown-leave-active {
  transition: opacity 0.5s ease-in-out, transform 0.5s ease;
}

.slidedown-enter-active {
  transition-delay: 0.5s;
}

.slidedown-enter,
.slidedown-leave-to {
  opacity: 0;
  transform: translateY(-100px);
}

.slidedown-enter-to,
.slidedown-leave {
  opacity: 1;
  transform: translateY(0);
}
//

@keyframes loading-line {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}
@keyframes skeleton-loading {
  0% {
    background-color: rgba(255, 255, 255, 0.01);
  }
  100% {
    background-color: rgba(255, 255, 255, 0.03);
  }
}

// --- vuetify classes(BAD realization) ---
.container {
  padding: 0 !important;
}
.v-label {
  // auth forms change
  font-size: 14px;
  // TODO: check other vuetify inputs
  // font-size: 14px !important;
  text-transform: uppercase;
  font-weight: 600;
}
.v-overlay__content {
  height: 100%;
  width: 100%;
}
.v-text-field input {
  caret-color: #cf1019 !important;
  padding: 5px 0 !important;
}
.theme--dark.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: rgba(255, 255, 255, 0.4) !important;
}
.theme--dark.v-label {
  color: rgba(255, 255, 255, 0.4) !important;
}
.theme--light.v-navigation-drawer {
  z-index: 999999;
}
.v-text-field > .v-input__control > .v-input__slot:after {
  border-width: 0.5px 0 0.5px 0 !important;
}
// --- end vuetify classes ---

// Hide zendesk widget on print view
@media print {
  #webWidget,
  #launcher {
    display: none !important;
  }
}

@media screen and (max-width: 1025px) {
  .has-tickets ~ div > #launcher {
    bottom: 74px !important;
  }
}
@media screen and (max-width: 900px) {
}
@media screen and (max-width: 499px) {
  .mem-show-mobile {
    display: block;
  }
  .mem-show-desktop {
    display: none;
  }
}
@media screen and (max-width: 375px) {
  // --- vuetify classes ---
  .container {
    padding: 0 !important;
  }
  .v-dialog {
    position: absolute;
    bottom: 21px;
    margin: 0 !important;
    padding: 0 8px;
    box-shadow: none !important;
  }
  .v-dialog--fullscreen {
    padding: 0;
  }
}
// --- end vuetify classes ---

// ----- refactor -----
.mem-required-input {
  i {
    font-size: 20px !important;
    margin-bottom: 22px;
  }
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-center-space-b {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex-center-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}
.mem-divider-h {
  border-left: 1px solid #aaaaaa;
  height: 65px;
  opacity: 0.4;
}
.mem-text-bold {
  font-weight: bold;
}

// buttons
.mem-btn {
  height: 44px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 22.7053px;
  padding: 0 24px;
  letter-spacing: 0.04em;
  font-size: 14px;
  transition: box-shadow 0.18s ease-in-out;
}
.mem-btn:focus {
  box-shadow: 0px 0px 20px #2596ff;
  outline: none;
}
.btn-primary {
  color: #ffffff;
  background: #cf1019;
  opacity: 1;
  transition: opacity 0.2s ease-in;
}
.btn-primary:hover {
  opacity: 0.7;
  // background: linear-gradient(
  //     0deg,
  //     rgba(255, 255, 255, 0.3),
  //     rgba(255, 255, 255, 0.3)
  //   ),
  //   #cf1019;
  // background: #CE6062;
}

// dark buttons
.btn-secondary-dark {
  color: #191818 !important;
  background: #ffffff;
}
.btn-secondary-dark:hover {
  color: #ffffff !important;
  background: #cf1019;
  // transition: 150ms linear;
}
.btn-tertiary-dark {
  border: 1px solid rgba(255, 255, 255, 0.4);
}
.btn-tertiary-dark:hover {
  color: #191818;
  background: #ffffff;
  border: 1px solid #ffffff;
}
.btn-tertiary-dark:disabled {
  border: 1px solid rgba(255, 255, 255, 0.21);
  background: inherit;
  color: rgba(255, 255, 255, 0.2);
  cursor: default !important;
}
.btn-text-dark {
  color: #ffffff;
}
.btn-text-dark:hover {
  background: rgba(255, 255, 255, 0.3);
}

// light buttons
.btn-secondary-light {
  color: #ffffff;
  background: #121212;
}
.btn-secondary-light:hover {
  color: #ffffff;
  background: #cf1019;
  // transition: 150ms linear;
}
.btn-tertiary-light {
  color: #121212;
  border: 1px solid rgba(0, 0, 0, 0.4);
}
.btn-tertiary-light:hover {
  color: #ffffff;
  background: #121212;
  border: 1px solid #121212;
}
.btn-text-light {
  color: #121212;
}
.btn-text-light:hover {
  background: rgba(0, 0, 0, 0.1);
}

.mem-button-padding {
  padding: 0 24px !important;
}
.mem-btn-border {
  border-color: #757474;
}
.mem-btn-bold {
  font-weight: bold;
}
// ----- end refactor -----
</style>
