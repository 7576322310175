import Vue from "vue";
import VueI18n from "vue-i18n";

import newTexts from "./locales/en";

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: "en",
  fallbackLocale: "en",
  silentTranslationWarn: true,
});

// function loadLocaleMessages () {
//   const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
//   const messages = {}
//   locales.keys().forEach(key => {
//     const matched = key.match(/([A-Za-z0-9-_]+)\./i)
//     if (matched && matched.length > 1) {
//       const locale = matched[1]
//       messages[locale] = locales(key)
//     }
//   })
//   return messages
// }

export async function loadLanguageAsync(lang, list) {
  let defaultLang = "en";
  if (lang) defaultLang = lang;

  // i18n.setLocaleMessage(defaultLang, list);
  i18n.setLocaleMessage(defaultLang, Object.assign(newTexts, list));

  let result = await setI18nLanguage(defaultLang);
  return result;
}

async function setI18nLanguage(lang) {
  i18n.locale = lang;
  // axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector("html").setAttribute("lang", lang);
  return lang;
}

// export default new VueI18n({
//   locale: process.env.VUE_APP_I18N_LOCALE || 'en',
//   fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
//   messages: loadLocaleMessages()
// })
