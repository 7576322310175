import Vue from "vue";

export default {
  setCurrent: (state, payload) => {
    state.current = payload;
  },
  setPagination: (state, payload) => {
    state.pagination = payload;
  },
  setCurrentField: (state, payload) => {
    const { field, value } = payload;
    if (field) {
      Vue.set(state.current, field, value);
    }
  },
  setList: (state, payload) => {
    state.list = payload;
  },
  setListItem: (state, payload) => {
    const { index, item } = payload;
    if (index) {
      Vue.set(state.list, index, item);
    }
  },
  setListItemField: (state, payload) => {
    const { index, field, value } = payload;
    if (index && state.list[index]) {
      Vue.set(state.list[index], field, value);
    }
  },
  setMyTeams: (state, payload) => {
    state.myTeams = payload;
  },
  updateTeamEventsList: (state, payload) => {
    state.teamEvents = payload;
  },
  updateTeamEventMembers: (state, list) => {
    state.teamEventMembers = list;
  },
  updateTeamMembersList: (state, payload) => {
    state.teamMembers = payload;
  },
  updateTeamMembersBottomList: (state, list) => {
    state.teamMembersBottom = list;
  },
  removeMemberFromList(state, data) {
    let { index, list } = data;
    console.log(index, list);
    if (list === "top") state.teamMembers.splice(index, 1);
    if (list === "bottom") state.teamMembersBottom.splice(index, 1);
  },
  setLeaderboardOffset: (state, offset) => {
    state.leaderboardListParams.offset = offset;
  },
  setLeaderboardSortParam: (state, param) => {
    state.leaderboardListParams.sort = param.sort;
    state.leaderboardListParams.direction = param.direction;
  },
  setLeaderboardTotalCount: (state, count) => {
    state.leaderboardListParams.total = count;
  },
  setLeaderboardSearch: (state, text) => {
    state.leaderboardListParams.search = text;
  },
  setLeaderboardTableFilters: (state, filters) => {
    state.leaderboardCategories = filters.categories;
    state.leaderboardYears = filters.years;
  },
  setLeaderboardFilters: (state, filters) => {
    state.leaderboardListParams.category = filters.category;
    state.leaderboardListParams.year = filters.year;
  },
  updateMemberRole: (state, data) => {
    const { index, field, value, list } = data;
    if (list === "top" && state.teamMembers[index]) {
      Vue.set(state.teamMembers[index], field, value);
    }
    if (list === "bottom" && state.teamMembersBottom[index]) {
      Vue.set(state.teamMembersBottom[index], field, value);
    }
  }
};
