<template>
  
  <component
    :is="component"
    :item="item"
    :references="references"
    :is-mobile="isMobile"
    class="menu-item"
    :class="{ 'mobile-only': itemData.mobileOnly }"
  >
    <template v-if="itemData.children">
      <menu-item
        v-for="(link, i) in itemData.children"
        :key="`${deepLvl}-0-${i}${isMobile ? '-mobile' : ''}`"
        :deep-lvl="deepLvl + 1"
        :parent-index="`${deepLvl}-0-${i}${isMobile ? '-mobile' : ''}`"
        :item="link"
        :references="references"
      />
    </template>
  </component>
</template>

<script>
import MenuItemResolverMixin from "@/components/menu/provision/MenuItemResolverMixin";
import componentMenuColumn from "@/components/menu/content-parts/componentMenuColumn";
import componentLinkBig from "@/components/menu/content-parts/componentLinkBig";
import componentLinkSmall from "@/components/menu/content-parts/componentLinkSmall";
import componentMenuPromo from "@/components/menu/content-parts/componentMenuPromo";
import componentMobileAppLink from "@/components/menu/content-parts/componentMobileAppLink";
import componentMenuSidebar from "@/components/menu/content-parts/componentMenuSidebar";

const CONTENT_PARTS = {
  componentMenuColumn: componentMenuColumn,
  componentLinkBig: componentLinkBig,
  componentLinkSmall: componentLinkSmall,
  componentMenuPromo: componentMenuPromo,
  componentMobileAppLink: componentMobileAppLink,
  componentMenuSidebar: componentMenuSidebar,
};

export default {
  name: "MenuItem",
  mixins: [MenuItemResolverMixin],
  props: {
    deepLvl: {
      type: Number,
      default: 1,
    },
    parentIndex: {
      type: String,
      default: "",
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    component() {
      if (!CONTENT_PARTS[this.itemData.type]) {
        console.warn(this.itemData.type, this.parentIndex);
      }
      return CONTENT_PARTS[this.itemData.type];
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-item {
}
.mobile-only {
  display: none;
  // opacity: 0;
  // pointer-events: none;
  @media screen and (max-width: 1279px) {
    display: flex;
    // opacity: 1;
    // pointer-events: all;
  }
}
</style>
