export default {
  team: state => {
    return state.current || {};
  },
  teamsList: state => {
    return state.list || [];
  },
  myTeams: state => {
    return state.myTeams || [];
  },
  teamsPagination: state => {
    return state.pagination || {};
  },
  teamEvents: state => {
    return state.teamEvents;
  },
  teamEventMembers: state => {
    return state.teamEventMembers;
  },
  teamMembers: state => {
    return state.teamMembers;
  },
  teamMembersBottom: state => {
    return state.teamMembersBottom;
  },
  leaderboardParams: state => {
    return state.leaderboardListParams;
  },
  filterCategories: state => {
    return state.leaderboardCategories;
  },
  filterYears: state => {
    return state.leaderboardYears;
  }
};
