import humps from "humps";
import queryString from "query-string";
import { TERM_BLACKLIST } from "@/lists/blacklist";
import store from "@/store";
import { regions } from "@/lists";

export function getCensoredWords(value, completed = true) {
  if (value) {
    const blacklist = store?.state?.abuseWords || TERM_BLACKLIST;
    return blacklist.filter(
      (item) =>
        (item.toLowerCase() === value.toLowerCase() && completed) ||
        value.toLowerCase().includes(` ${item.toLowerCase()} `) ||
        (value.toLowerCase().includes(` ${item.toLowerCase()}`) && completed) ||
        value.toLowerCase().includes(`${item.toLowerCase()} `)
    );
  }

  return [];
}

export const getAbsoluteUrl = (function () {
  let a;

  return function (url) {
    if (!a) a = document.createElement("a");

    a.href = url;
    return a.href;
  };
})();

export class KeyConverter {
  static convertCallback = (key, convert) => {
    const lastSymbol = key.slice(-1);
    const newKey = convert(key);
    return lastSymbol === "-" ? `${newKey}-` : newKey;
  };

  static camelize = (object) =>
    humps.camelizeKeys(object, KeyConverter.convertCallback);

  static decamelize = (object) => {
    if (object && !(object instanceof File)) {
      if (object instanceof Array) {
        return object.map((item) => KeyConverter.decamelize(item));
      }
      if (typeof object === "object") {
        return Object.keys(object).reduce(
          (acc, next) => ({
            ...acc,
            [humps.decamelize(next)]: KeyConverter.decamelize(object[next]),
          }),
          {}
        );
      }
    }

    return object;
  };

  static stringify = (object) =>
    queryString.stringify(object, { arrayFormat: "bracket" });

  static makeRequestParams = (object) =>
    KeyConverter.stringify(KeyConverter.decamelize(object));

  static makeRequestData = (object) =>
    JSON.stringify(KeyConverter.decamelize(object));
}

export class UrlPath {
  static join = (path, baseUrl = "/") => {
    const preparedPath =
      path.trim()[0] === "/" ? path.trim().substring(1) : path.trim();
    if (!UrlPath.isRelative(path)) return preparedPath;
    const basePath = new URL(baseUrl);
    const targetUrl = new URL(
      `${basePath.pathname === "/" ? "" : basePath.pathname}/${preparedPath}`,
      basePath.origin
    );

    return targetUrl.toString();
  };

  static isRelative = (path) => path?.indexOf("//") < 0;
}

export class Localizer {
  static getCountiesConfig() {
    const countryArray = regions.map((item) => item.countries).flat();
    const countryCollection = {};
    countryArray.forEach((country) => {
      const langCollection = {};
      country.languages.forEach((lang) => {
        lang.countryCode = country.code;
        langCollection[lang.code] = lang;
      });
      country.lang = langCollection;
      countryCollection[country.code] = country;
    });
    return countryCollection;
  }

  static getLocalizeConfig(country, lang) {
    const contriesConfig = Localizer.getCountiesConfig();
    return contriesConfig[country]?.lang[lang];
  }

  static getLanguagesList() {
    return Object.values(Localizer.getCountiesConfig())
      .map((item) => item.languages)
      .flat();
  }

  static resolveLocale(country, lang) {
    const locale = {
      country: null,
      lang: null,
    };
    const preparedCountry = country?.toUpperCase();
    const languagesList = Localizer.getLanguagesList();
    if (
      languagesList.some(
        (item) => item.countryCode === preparedCountry && item.code === lang
      )
    ) {
      locale.lang = lang;
      locale.country = preparedCountry;
    } else if (
      languagesList.some((item) => item.countryCode === preparedCountry)
    ) {
      locale.lang = "en";
      locale.country = preparedCountry;
    } else {
      locale.lang = "en";
      locale.country = "US";
    }

    return locale;
  }
}

export class GooglePlaces {
  static componentForm = {
    street_number: "short_name",
    route: "long_name",
    locality: "long_name",
    administrative_area_level_1: "short_name",
    country: "short_name",
    postal_code: "short_name",
  };
  static init() {
    console.log("init places");
    let autocomplete = new google.maps.places.Autocomplete(
      document.getElementById("autocomplete"),
      { types: ["geocode"] }
    );
    autocomplete.setFields(["address_component"]);
    return autocomplete;
  }
}

export function pushDataLayerEvent(event, data) {
  if (!window.dataLayer) return;

  window.dataLayer.push({
    event,
    ...data,
  });
}

export default {
  getCensoredWords,
  getAbsoluteUrl,
  KeyConverter,
  UrlPath,
};
