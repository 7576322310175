<template>
  <div class="header-menu">
    <div
      class="menu-link"
      v-for="(item, index) in menuItems"
      v-bind:key="index"
    >
      <div class="link-name cursor-pointer">
        <span>{{ item.text }}</span>
        <!-- <div class="link-line"></div> -->
      </div>
      <div class="link-content" v-if="item.children">
        <div class="container">
          <menu-item
            v-for="(link, i) in item.children"
            :key="`0-${index}-${i}`"
            :parent-index="`0-${index}-${i}`"
            :item="link"
            :references="headerLinks.references"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { get } from "lodash";
import MenuItem from "@/components/menu/MenuItem";

export default {
  name: "HeaderMenu",
  components: { MenuItem },
  computed: {
    ...mapGetters(["headerLinks"]),
    menuItems() {
      let res = [];
      if (this.headerLinks?.menuItems) {
        res = this.headerLinks.menuItems.map((item) => ({
          origin: { ...item },
          data: this.headerLinks.references[item.sys.linkType][item.sys.id],
          text: get(
            this.headerLinks.references[item.sys.linkType][item.sys.id],
            "fields.text"
          ),
          type: get(
            this.headerLinks.references[item.sys.linkType][item.sys.id],
            "sys.contentType.sys.id"
          ),
          children: get(
            this.headerLinks.references[item.sys.linkType][item.sys.id],
            "fields.components"
          ),
        }));
      }
      return res;
    },
  },
};
</script>

<style lang="scss" scoped>
.header-menu {
  position: absolute;
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .menu-link {
    font-size: 18px;
    line-height: 14px;
    font-weight: bold;
    text-transform: uppercase;
    position: static;
    display: inline-flex;
    z-index: 3;
    align-items: center;
    height: 100%;
    padding: 0 17px;
    cursor: pointer;

    .link-name {
      position: relative;

      span:before {
        content: "";
        position: absolute;
        height: 4px;
        bottom: -33px;
        left: 0;
        right: 0;
        background-color: #fff;
        width: 100%;
        max-width: 0;
        transition: all 0.05s ease-in-out 0s;
      }
    }

    .link-content {
      background: #121212;
      // display: none;
      display: flex;
      flex-direction: row;
      justify-content: center;
      position: absolute;
      left: 0;
      top: 80px;
      width: 100vw;
      min-height: 396px;
      padding: 40px 34px;

      opacity: 0;
      visibility: hidden;

      // transition-delay: 0.3s;

      transition: opacity 0.1s linear 0.4s, visibility 0ms linear 0.41s;

      .container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        max-width: min(1078px, 100%);
      }
    }

    &:hover {
      .link-name {
        span:before {
          max-width: 100%;
        }
      }
      .link-content {
        opacity: 1;
        visibility: visible;
        transition: opacity 0.1s, visibility 0ms;
        transition-delay: 0.3s;
      }
    }
  }
}
</style>
