const TICKETS_COOKIE_NAME = "cart_token";

// think about it https://www.npmjs.com/package/vue-cookies-reactive
export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function getToken() {
  return getCookie("user").substr(7);
}

export function deleteAllCookies() {
  // TODO: remove legacy regions support
  let cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    let eqPos = cookie.indexOf("=");
    let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    if (location.hostname === "localhost")
      document.cookie = name + "=;domain=localhost;path=/;max-age=-1";
    else document.cookie = name + "=;domain=.spartan.com;path=/;max-age=-1";
  }
}

function setCookie(name, value, days = 90) {
  var expires = "";
  console.log(`Cookie ${name} lifetime:`, days);
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  if (process.env.VUE_APP_ENV === "local")
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  else
    document.cookie =
      name +
      "=" +
      (value || "") +
      expires +
      "; path=/" +
      "; domain=.spartan.com";
}

// source https://learn.javascript.ru/cookie
export function setCookieV2(name, value, options = {}) {
  options = {
    path: "/",
    ...options,
  };

  if (process.env.VUE_APP_ENV !== "local") options.domain = ".spartan.com";
  console.log("Cookie options", options);

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie =
    encodeURIComponent(name) + "=" + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += "; " + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue;
    }
  }

  document.cookie = updatedCookie;
}

export { TICKETS_COOKIE_NAME, setCookie };
