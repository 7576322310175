import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: "#FFFFFF",
        secondary: "#000000",
        accent: "#CF1019",
        error: "#FF0707",
        // info: '',
        // success: '',
        // warning: '',
      },
    },
  },
});
