export default {
  currentTicket: state => state.currentTicket,
  inviteTeamInfo: state => state.inviteTeamInfo,
  // storedTickets: state => state.storedTickets,
  ticketAttendees: state => state.attendees,
  hasUnclaimedTickets: (state, getters, rootState) => {
    if (!rootState.events?.event?.ticketCollection) return false
    return Object.values(rootState.events.event.ticketCollection)?.some(item => !item.is_claimed)
  }
};
