import visa from "@/assets/paymentMethodIcons/1.png";
import mastercard from "@/assets/paymentMethodIcons/2.png";
import diners from "@/assets/paymentMethodIcons/10.png";
import discover from "@/assets/paymentMethodIcons/14.png";
import jcb from "@/assets/paymentMethodIcons/16.png";
import amex from "@/assets/paymentMethodIcons/22.png";
// unionpay
// jcb
// diners
// discover
// mastercard
// amex
export const CREDIT_CARDS = [
  { brand: "visa", icon: visa },
  { brand: "jcb", icon: jcb },
  { brand: "diners", icon: diners },
  { brand: "discover", icon: discover },
  { brand: "mastercard", icon: mastercard },
  { brand: "amex", icon: amex },
];
