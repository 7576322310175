import { getCookie } from "../../cookie.js";
import config from "@/config";

const API = config.apiAccount;
let API_MEMBERSHIP = "";

if (process.env.VUE_APP_ENV === "local")
  API_MEMBERSHIP = "https://account-staging.spartan.com";

export default {
  state: {
    badges: [],
    challenges: [],
  },
  actions: {
    async getBadges(ctx) {
      const res = await fetch(`${API}/badges`, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: getCookie("user"),
        }),
      });
      const badgesList = await res.json();
      if (badgesList.badges) ctx.commit("updateBadgesList", badgesList.badges);
    },
    async getChallenges(ctx) {
      const res = await fetch(`${API}/challenges`, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: getCookie("user"),
        }),
      });
      const challengesList = await res.json();
      ctx.commit("updateChallengesList", challengesList.challenges);
    },
    async commitToChallenge(ctx, challengeId) {
      const res = await fetch(`${API}/challenges/${challengeId}/commit`, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: getCookie("user"),
        }),
      });
      // const commitStasus = await res.json();
      // console.log(commitStasus, "status")
      console.log(challengeId, "id");
      ctx.dispatch("getChallenges");
      return res.status;
    },
    async getChallengeShareLink(ctx, data) {
      const res = await fetch(
        `${API_MEMBERSHIP}/api/v1/challenges/${data.challengeId}/share?lang=${data.lang}`,
        {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: getCookie("user"),
          }),
        }
      );
      const linkData = await res.json();
      return linkData;
    },
  },
  mutations: {
    updateBadgesList(state, list) {
      state.badges = list;
    },
    updateChallengesList(state, list) {
      state.challenges = list;
    },
  },
  getters: {
    badgesList(state) {
      return state.badges;
    },
    challengesList(state) {
      return state.challenges;
    },
  },
};
