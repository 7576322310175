export const PHONE_CODES = [
  { phone_code: "93", country_code: "AF" },
  { phone_code: "355", country_code: "AL" },
  { phone_code: "213", country_code: "DZ" },
  { phone_code: "1684", country_code: "AS" },
  { phone_code: "376", country_code: "AD" },
  { phone_code: "244", country_code: "AO" },
  { phone_code: "1264", country_code: "AI" },
  { phone_code: "672", country_code: "AQ" },
  { phone_code: "1268", country_code: "AG" },
  { phone_code: "54", country_code: "AR" },
  { phone_code: "374", country_code: "AM" },
  { phone_code: "297", country_code: "AW" },
  { phone_code: "61", country_code: "AU" },
  { phone_code: "43", country_code: "AT" },
  { phone_code: "994", country_code: "AZ" },
  { phone_code: "1242", country_code: "BS" },
  { phone_code: "973", country_code: "BH" },
  { phone_code: "880", country_code: "BD" },
  { phone_code: "1246", country_code: "BB" },
  { phone_code: "375", country_code: "BY" },
  { phone_code: "32", country_code: "BE" },
  { phone_code: "501", country_code: "BZ" },
  { phone_code: "229", country_code: "BJ" },
  { phone_code: "1441", country_code: "BM" },
  { phone_code: "975", country_code: "BT" },
  { phone_code: "591", country_code: "BO" },
  { phone_code: "387", country_code: "BA" },
  { phone_code: "267", country_code: "BW" },
  { phone_code: "55", country_code: "BR" },
  { phone_code: "246", country_code: "IO" },
  { phone_code: "1284", country_code: "VG" },
  { phone_code: "673", country_code: "BN" },
  { phone_code: "359", country_code: "BG" },
  { phone_code: "226", country_code: "BF" },
  { phone_code: "257", country_code: "BI" },
  { phone_code: "855", country_code: "KH" },
  { phone_code: "237", country_code: "CM" },
  { phone_code: "1", country_code: "CA" },
  { phone_code: "238", country_code: "CV" },
  { phone_code: "1345", country_code: "KY" },
  { phone_code: "236", country_code: "CF" },
  { phone_code: "235", country_code: "TD" },
  { phone_code: "56", country_code: "CL" },
  { phone_code: "86", country_code: "CN" },
  { phone_code: "61", country_code: "CX" },
  { phone_code: "61", country_code: "CC" },
  { phone_code: "57", country_code: "CO" },
  { phone_code: "269", country_code: "KM" },
  { phone_code: "682", country_code: "CK" },
  { phone_code: "506", country_code: "CR" },
  { phone_code: "385", country_code: "HR" },
  { phone_code: "53", country_code: "CU" },
  { phone_code: "599", country_code: "CW" },
  { phone_code: "357", country_code: "CY" },
  { phone_code: "420", country_code: "CZ" },
  { phone_code: "243", country_code: "CD" },
  { phone_code: "45", country_code: "DK" },
  { phone_code: "253", country_code: "DJ" },
  { phone_code: "1767", country_code: "DM" },
  { phone_code: "1809", country_code: "DO" },
  { phone_code: "670", country_code: "TL" },
  { phone_code: "593", country_code: "EC" },
  { phone_code: "20", country_code: "EG" },
  { phone_code: "503", country_code: "SV" },
  { phone_code: "240", country_code: "GQ" },
  { phone_code: "291", country_code: "ER" },
  { phone_code: "372", country_code: "EE" },
  { phone_code: "251", country_code: "ET" },
  { phone_code: "500", country_code: "FK" },
  { phone_code: "298", country_code: "FO" },
  { phone_code: "679", country_code: "FJ" },
  { phone_code: "358", country_code: "FI" },
  { phone_code: "33", country_code: "FR" },
  { phone_code: "689", country_code: "PF" },
  { phone_code: "241", country_code: "GA" },
  { phone_code: "220", country_code: "GM" },
  { phone_code: "995", country_code: "GE" },
  { phone_code: "49", country_code: "DE" },
  { phone_code: "233", country_code: "GH" },
  { phone_code: "350", country_code: "GI" },
  { phone_code: "30", country_code: "GR" },
  { phone_code: "299", country_code: "GL" },
  { phone_code: "1473", country_code: "GD" },
  { phone_code: "1671", country_code: "GU" },
  { phone_code: "502", country_code: "GT" },
  { phone_code: "441481", country_code: "GG" },
  { phone_code: "224", country_code: "GN" },
  { phone_code: "245", country_code: "GW" },
  { phone_code: "592", country_code: "GY" },
  { phone_code: "509", country_code: "HT" },
  { phone_code: "504", country_code: "HN" },
  { phone_code: "852", country_code: "HK" },
  { phone_code: "354", country_code: "IS" },
  { phone_code: "36", country_code: "HU" },
  { phone_code: "91", country_code: "IN" },
  { phone_code: "62", country_code: "ID" },
  { phone_code: "98", country_code: "IR" },
  { phone_code: "964", country_code: "IQ" },
  { phone_code: "353", country_code: "IE" },
  { phone_code: "441624", country_code: "IM" },
  { phone_code: "972", country_code: "IL" },
  { phone_code: "39", country_code: "IT" },
  { phone_code: "225", country_code: "CI" },
  { phone_code: "1876", country_code: "JM" },
  { phone_code: "81", country_code: "JP" },
  { phone_code: "441534", country_code: "JE" },
  { phone_code: "962", country_code: "JO" },
  { phone_code: "7", country_code: "KZ" },
  { phone_code: "254", country_code: "KE" },
  { phone_code: "686", country_code: "KI" },
  { phone_code: "383", country_code: "XK" },
  { phone_code: "965", country_code: "KW" },
  { phone_code: "996", country_code: "KG" },
  { phone_code: "856", country_code: "LA" },
  { phone_code: "371", country_code: "LV" },
  { phone_code: "961", country_code: "LB" },
  { phone_code: "266", country_code: "LS" },
  { phone_code: "231", country_code: "LR" },
  { phone_code: "218", country_code: "LY" },
  { phone_code: "423", country_code: "LI" },
  { phone_code: "370", country_code: "LT" },
  { phone_code: "352", country_code: "LU" },
  { phone_code: "853", country_code: "MO" },
  { phone_code: "389", country_code: "MK" },
  { phone_code: "261", country_code: "MG" },
  { phone_code: "265", country_code: "MW" },
  { phone_code: "60", country_code: "MY" },
  { phone_code: "960", country_code: "MV" },
  { phone_code: "223", country_code: "ML" },
  { phone_code: "356", country_code: "MT" },
  { phone_code: "692", country_code: "MH" },
  { phone_code: "222", country_code: "MR" },
  { phone_code: "230", country_code: "MU" },
  { phone_code: "262", country_code: "YT" },
  { phone_code: "52", country_code: "MX" },
  { phone_code: "691", country_code: "FM" },
  { phone_code: "373", country_code: "MD" },
  { phone_code: "377", country_code: "MC" },
  { phone_code: "976", country_code: "MN" },
  { phone_code: "382", country_code: "ME" },
  { phone_code: "1664", country_code: "MS" },
  { phone_code: "212", country_code: "MA" },
  { phone_code: "258", country_code: "MZ" },
  { phone_code: "95", country_code: "MM" },
  { phone_code: "264", country_code: "NA" },
  { phone_code: "674", country_code: "NR" },
  { phone_code: "977", country_code: "NP" },
  { phone_code: "31", country_code: "NL" },
  { phone_code: "599", country_code: "AN" },
  { phone_code: "687", country_code: "NC" },
  { phone_code: "64", country_code: "NZ" },
  { phone_code: "505", country_code: "NI" },
  { phone_code: "227", country_code: "NE" },
  { phone_code: "234", country_code: "NG" },
  { phone_code: "683", country_code: "NU" },
  { phone_code: "850", country_code: "KP" },
  { phone_code: "1670", country_code: "MP" },
  { phone_code: "47", country_code: "NO" },
  { phone_code: "968", country_code: "OM" },
  { phone_code: "92", country_code: "PK" },
  { phone_code: "680", country_code: "PW" },
  { phone_code: "970", country_code: "PS" },
  { phone_code: "507", country_code: "PA" },
  { phone_code: "675", country_code: "PG" },
  { phone_code: "595", country_code: "PY" },
  { phone_code: "51", country_code: "PE" },
  { phone_code: "63", country_code: "PH" },
  { phone_code: "64", country_code: "PN" },
  { phone_code: "48", country_code: "PL" },
  { phone_code: "351", country_code: "PT" },
  { phone_code: "1787", country_code: "PR" },
  { phone_code: "974", country_code: "QA" },
  { phone_code: "242", country_code: "CG" },
  { phone_code: "262", country_code: "RE" },
  { phone_code: "40", country_code: "RO" },
  { phone_code: "7", country_code: "RU" },
  { phone_code: "250", country_code: "RW" },
  { phone_code: "590", country_code: "BL" },
  { phone_code: "290", country_code: "SH" },
  { phone_code: "1869", country_code: "KN" },
  { phone_code: "1758", country_code: "LC" },
  { phone_code: "590", country_code: "MF" },
  { phone_code: "508", country_code: "PM" },
  { phone_code: "1784", country_code: "VC" },
  { phone_code: "685", country_code: "WS" },
  { phone_code: "378", country_code: "SM" },
  { phone_code: "239", country_code: "ST" },
  { phone_code: "966", country_code: "SA" },
  { phone_code: "221", country_code: "SN" },
  { phone_code: "381", country_code: "RS" },
  { phone_code: "248", country_code: "SC" },
  { phone_code: "232", country_code: "SL" },
  { phone_code: "65", country_code: "SG" },
  { phone_code: "1721", country_code: "SX" },
  { phone_code: "421", country_code: "SK" },
  { phone_code: "386", country_code: "SI" },
  { phone_code: "677", country_code: "SB" },
  { phone_code: "252", country_code: "SO" },
  { phone_code: "27", country_code: "ZA" },
  { phone_code: "82", country_code: "KR" },
  { phone_code: "211", country_code: "SS" },
  { phone_code: "34", country_code: "ES" },
  { phone_code: "94", country_code: "LK" },
  { phone_code: "249", country_code: "SD" },
  { phone_code: "597", country_code: "SR" },
  { phone_code: "47", country_code: "SJ" },
  { phone_code: "268", country_code: "SZ" },
  { phone_code: "46", country_code: "SE" },
  { phone_code: "41", country_code: "CH" },
  { phone_code: "963", country_code: "SY" },
  { phone_code: "886", country_code: "TW" },
  { phone_code: "992", country_code: "TJ" },
  { phone_code: "255", country_code: "TZ" },
  { phone_code: "66", country_code: "TH" },
  { phone_code: "228", country_code: "TG" },
  { phone_code: "690", country_code: "TK" },
  { phone_code: "676", country_code: "TO" },
  { phone_code: "1868", country_code: "TT" },
  { phone_code: "216", country_code: "TN" },
  { phone_code: "90", country_code: "TR" },
  { phone_code: "993", country_code: "TM" },
  { phone_code: "1649", country_code: "TC" },
  { phone_code: "688", country_code: "TV" },
  { phone_code: "1340", country_code: "VI" },
  { phone_code: "44", country_code: "GB" },
  { phone_code: "58", country_code: "VE" },
  { phone_code: "84", country_code: "VN" },
  { phone_code: "598", country_code: "UY" },
  { phone_code: "998", country_code: "UZ" },
  { phone_code: "678", country_code: "VU" },
  { phone_code: "379", country_code: "VA" },
  { phone_code: "681", country_code: "WF" },
  { phone_code: "212", country_code: "EH" },
  { phone_code: "967", country_code: "YE" },
  { phone_code: "260", country_code: "ZM" },
  { phone_code: "263", country_code: "ZW" },
  { phone_code: "1", country_code: "US" },
  { phone_code: "256", country_code: "UG" },
  { phone_code: "380", country_code: "UA" },
  { phone_code: "971", country_code: "AE" },
];
